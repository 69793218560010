import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Ilustration from 'Assets/save-illustration.svg';
import Button from 'Components/Button';
import { Translate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  background: rgb(248,181,161);
  background: linear-gradient(180deg, rgba(248,181,161,1) 0%, rgba(248,151,151,1) 100%);
  padding: 30px 0 30px 0;

  h2 {
    font-weigth: ${variables.typography.baseHeadingFontWeight};
    padding: ${variables.typography.baseHeadingPadding};
    color: ${variables.color.white};
  }

  p {
    color: ${variables.color.white};
    letter-spacing: ${variables.typography.baseLetterSpacing};
  }

  img {
    width: 300px;
  }
`

const Column = styled(Col)`
  padding: 10px 30px;
`

const Save = () => (
  <Wrapper>
    <Container>
      <Row>
        <Column sm='6' className='align-self-center text-center'>
          <img src={Ilustration} alt='' />
        </Column>
        <Column sm='6' className='align-self-center'>
          <div>
            <h2><Translate>translation:homepage.save.heading</Translate></h2>
            <p><Translate>translation:homepage.save.description</Translate></p>
            <Link to='#'>
              <Button color='white' style={{ borderRadius: '20px' }}>
                <Translate>translation:homepage.save.button.value</Translate>
              </Button>
            </Link>
          </div>
        </Column>
      </Row>
    </Container>
  </Wrapper>
);

export default Save;