import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AboutIlustration from 'Assets/about-illustration.svg';
import ArrowIcon from 'Assets/arrow.svg';
import Button from 'Components/Button';
import { Translate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  padding: 30px 0 30px 0;

  h2 {
    color: ${variables.color.primary};
    padding: ${variables.typography.baseHeadingPadding};
  }

  h5 {
    font-weight: 600;
    font-size: 18px;
  }
`;

const Arrow = styled.img`
  width: 20px;
  margin-left: 10px;
`

const Image = styled.img`
  max-width: 100%;
`;

const About = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col lg='6' className='text-center'>
          <Image src={AboutIlustration} />
        </Col>
        <Col lg='6'>
          <h2><Translate>translation:about.heading</Translate></h2>
          <Row>
            <Col sm='6'>
              <h5><Translate>translation:about.content.addCodes</Translate></h5>
              <p><Translate>translation:about.content.contentCodes</Translate></p>
              <h5><Translate>translation:about.content.saveMoney</Translate></h5>
              <p><Translate>translation:about.content.saveMoneyContent</Translate></p>
            </Col>
            <Col sm='6'>
              <h5><Translate>translation:about.content.shareCodes</Translate></h5>
              <p><Translate>translation:about.content.shareCodesContent</Translate></p>
              <h5><Translate>translation:about.content.beHappier</Translate></h5>
              <p><Translate>translation:about.content.beHappierContent</Translate></p>
            </Col>
          </Row>
          <Link to='#'>
            <Button color='link-pink'>
              <Translate>translation:about.button.value</Translate>
              <Arrow src={ArrowIcon} />
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default About;
