import React, { Suspense } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import Router from './Router';
import Loading from 'Components/Loading';
import { AuthProvider } from 'Foundation/Authentication'
import GlobalStyle from 'Utils/Styles'
import 'moment/min/locales';

const App = () => {
  return (
    <>
      <ToastProvider autoDismiss>
        <AuthProvider>
          <Suspense fallback={<Loading />}>
            <Router />
          </Suspense>
        </AuthProvider>
      </ToastProvider>
      <GlobalStyle />
    </>
  );
}

export default App;
