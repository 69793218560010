import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import AboutSectionImg from 'Assets/about-header.png';
import AboutDetails from 'Components/About';
import SearchInput from 'Components/Input/Search';
import PopularCompanies from 'Components/Popular/Company';
import Statistics from 'Components/Statistics';
import { HtmlTranslate, Translate } from 'Foundation/Translations';
import Layout from 'Templates/Layout/Main';
import { variables } from 'Utils/Styles';

const TopSection = styled.div`
  background-image: url(${AboutSectionImg});
  background-size: cover;
  height: 600px;
  width: 100%;
  padding-top: 150px;
  margin-bottom: 70px;

  .banner-text {
    padding-top: 50px;
  }

  h1 {
    color: ${variables.color.white};
    font-weight: 600;
    font-size: 56px;
    margin-bottom: ${variables.spacing.larger};
  }

  h1 span {
    color: ${variables.color.primary};
    display: block;
  }
`;

const Headline = () => {
  const { t } = useTranslation();

  return (
    <TopSection>
      <Container>
        <Row>
          <Col sm='6' className='banner-text offset-sm-6'>
            <h1><HtmlTranslate>translation:homepage.saveMoney</HtmlTranslate></h1>
            <SearchInput placeholder={t('translation:forms.placeholders.shop')} />
          </Col>
        </Row>
      </Container>
    </TopSection>
  );
};

const About = () => {
  const { t } = useTranslation();

  return (
    <Layout
      metaData={{
        title: t('seo:pages.about.title'),
        description: t('seo:pages.about.description'),
      }}
    >
      <Headline />
      <AboutDetails />
      <PopularCompanies
        amount={12}
        backgroundColor={variables.color.gray}
        heading={<Translate>translation:popular.offers</Translate>}
      />
      <Statistics />
    </Layout>
  );
}

export default About;
