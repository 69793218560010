import { string } from 'prop-types';
import { get, head, isEmpty, map, uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { useToasts } from 'react-toast-notifications';
import Item from 'Components/Code/Item';
import Axios from 'Foundation/Axios';
import { normalizeCoupons } from 'Foundation/Normalizers';
import { Translate } from 'Foundation/Translations';

const Codes = ({ color }) => {
  const { addToast } = useToasts();
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCodes = async () => {
      try {
          const { data: { data, included } } = await Axios.get('/coupons?per_page=4');

          setCodes(normalizeCoupons(data, included));
          setLoading(false);
      } catch (err) {
        addToast(
          <Translate>notifications:general.error</Translate>,
          { appearance: 'error' },
        );
      }
    };

    fetchCodes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Spinner color='secondary' />
  }

  if (isEmpty(codes)) {
    return null;
  }

  return (
    <div>
      <Row>
        {map(codes, ({ name, code, comments, highlighted, categories, company }) => (
          <Col md='3' key={uniqueId()}>
            <Item
              name={get(head(categories), 'name', '')}
              highlighted={highlighted}
              heading={name}
              content={comments || ''}
              companyName={company.name}
              color={color}
              code={code}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
};

Codes.propTypes = {
  color: string.isRequired,
};

export default Codes;
