import { isString, map } from 'lodash';
import {
  array,
  bool,
  func,
  node,
  string,
} from 'prop-types';
import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import styled from 'styled-components';
import { variables } from 'Utils/Styles';

const StyledLabel = styled(Label)`
  color: ${props => variables.color[props.color]};
  text-transform: ${props => props.check ? '' : 'uppercase'};
  letter-spacing: ${props => props.check ? '1px' : '3px'};
  font-size: ${variables.typography.baseFontSize};
`;

const StyledInput = styled(Input)`
  background-color: transparent;
  border: 0;
  border-bottom: 1.4px solid;
  border-bottom-color: ${variables.color.darkBlue};
  border-radius: 0;
`;

const Wrapper = styled.div`
  margin-bottom: 10px;

  input[type='checkbox'] {
    opacity: 0.6;
    box-shadow: 0px 0px 0px 1px rgba(246, 100, 130, 1);
    cursor: pointer;
  }

  #info-public-code {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    background-color: ${variables.color.pink};
  }
`;

const Inputs = ({
  color,
  value,
  errorMessage,
  label,
  name,
  onChange,
  options,
  required,
  type,
  ...rest
}) => {
  if (type === 'checkbox') {
    return (
      <FormGroup check>
        <Wrapper>
          <StyledInput type='checkbox' onChange={onChange} checked={value} {...rest} />
          <StyledLabel check>
            {label}
          </StyledLabel>
        </Wrapper>
      </FormGroup>
    )
  }

  if (type === 'select') {
    return (
      <FormGroup>
        <StyledLabel color={color} for={name}>{label}</StyledLabel>
        <StyledInput type={type} name={name} onChange={onChange} value={value} {...rest}>
          {map(options, ({ id, name }) => <option value={id} key={id}>{name}</option>)}
        </StyledInput>
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <StyledLabel color={color} for={name}>{label}</StyledLabel>
      <StyledInput
        invalid={isString(errorMessage)}
        type={type}
        name={name}
        onChange={onChange}
        required={required}
        value={value}
        {...rest}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </FormGroup>
  )
};

Inputs.propTypes = {
  type: string,
  name: string.isRequired,
  label: node.isRequired,
  onChange: func.isRequired,
  options: array,
  errorMessage: node,
  required: bool,
  value: node,
};

Inputs.defaultProps = {
  type: 'text',
  color: 'primary',
  errorMessage: null,
  required: false,
  options: [],
  value: '',
}

export default Inputs;
