import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import Number1 from 'Assets/1.svg';
import Number2 from 'Assets/2.svg';
import Number3 from 'Assets/3.svg';
import Line from 'Assets/Line.svg';
import { Translate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  padding: 30px 0 30px 0;

  h2 {
    color: ${variables.color.primary};
    font-weight: ${variables.typography.baseHeadingFontWeight};
    padding: ${variables.typography.baseHeadingPadding};
  }

  .content {
    max-width: 260px;
    color: ${variables.color.darkBlue};
    margin: 0 auto;
  }

  .number {
    width: 50px;
  }

  .line {
    width: 80%;
    padding: 20px;
  }
`

const NumberWrapper = styled.div`
  padding: 10px 0 15px 0;
`

const About = () => (
  <Wrapper>
    <Container>
      <h2><Translate>translation:homepage.about.heading</Translate></h2>
      <Row>
        <Col sm='4'>
          <NumberWrapper>
            <img src={Number1} className='number' alt='' />
            <img src={Line} className='d-none d-lg-inline line' alt='' />
          </NumberWrapper>
          <div className='content'>
            <p><Translate>translation:homepage.about.number1</Translate></p>
          </div>
        </Col>
        <Col sm='4'>
          <NumberWrapper>
            <img src={Number2} className='number' alt='' />
            <img src={Line} className='d-none d-lg-inline line' alt='' />
          </NumberWrapper>
          <div className='content'>
            <p><Translate>translation:homepage.about.number2</Translate></p>
          </div>
        </Col>
        <Col sm='4'>
          <NumberWrapper>
            <img src={Number3} className='number' alt='' />
          </NumberWrapper>
          <div className='content'>
            <p><Translate>translation:homepage.about.number3</Translate></p>
          </div>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default About;