import { map, take, uniqueId } from 'lodash';
import { number, node, bool } from 'prop-types';
import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import Aliexpres from 'Assets/Companies/aliexpres.png';
import Bestseller from 'Assets/Companies/bestseller.png';
import Coop from 'Assets/Companies/coop.png';
import Lego from 'Assets/Companies/lego.png';
import Mac from 'Assets/Companies/mac.png';
import { Translate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  padding: 30px 0 30px 0;
  background-color: ${props => props.backgroundColor || variables.color.white};

  h2 {
    padding: ${variables.typography.baseHeadingPadding};
  }
`;

const CompanyLogo = styled.img`
  padding: 30px;
  width: 100%;
`

const PopularCompanies = ({ amount, backgroundColor, heading, includeHeading }) => {
  const companies = [
    Aliexpres,
    Bestseller,
    Coop,
    Lego,
    Mac,
    Aliexpres,
    Bestseller,
    Coop,
    Lego,
    Mac,
    Aliexpres,
    Bestseller,
    Coop,
    Lego,
    Mac,
    Bestseller,
    Coop,
    Lego,
  ];

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Container>
        {includeHeading && <h2>{heading}</h2>}
        <Row className='text-center'>
          {map(take(companies, amount), company => (
            <Col key={uniqueId()} sm='4' md='3' lg='2' xs='6'>
              <CompanyLogo src={company} alt='Company' />
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  );
};

PopularCompanies.propTypes = {
  amount: number,
  heading: node,
  includeHeading: bool,
};

PopularCompanies.defaultProps = {
  amount: 18,
  heading: <Translate>translation:popular.heading</Translate>,
  includeHeading: true,
};

export default PopularCompanies;
