import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import Card from 'Components/Statistics/Card';

const Wrapper = styled.div`
  background: rgb(248,181,161);
  background: linear-gradient(180deg, rgba(248,181,161,1) 0%, rgba(248,151,151,1) 100%);
  padding: 30px 0 30px 0;
`

const Statistics = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col sm='6' lg='3'>
          <Card
            type='users'
            amount='+20 000'
            value='users'
          />
        </Col>
        <Col sm='6' lg='3'>
          <Card
            type='brands'
            amount='569'
            value='brands'
          />
        </Col>
        <Col sm='6' lg='3'>
          <Card
            type='codes'
            amount='+10 000'
            value='active codes'
          />
        </Col>
        <Col sm='6' lg='3'>
          <Card
            type='offers'
            amount='125'
            value='offers today'
          />
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Statistics;
