import { createGlobalStyle } from 'styled-components'

export const variables = {
  color: {
    primary: '#F79484',
    primaryLight: '#fdedee',
    lightGray: '#f8f8f8',
    darkGray: '#0F0D3E',
    white: '#fff',
    darkBlue: '#0f0e3e',
    lightBlue: '#88869f',
    pink: '#f66482',
    lightPink: '#fab0c0',
    gray: '#f8f8f8',
    primaryGradient: 'linear-gradient(180deg, rgba(248,181,161,1) 0%, rgba(248,151,151,1) 100%)',
    mediumGray: '#d3d3d3',
  },
  typography: {
    baseFontFamily: 'Lato',
    smallFontSize: '12px',
    baseFontSize: '14px',
    baseLetterSpacing: '0.5px',
    baseHeadingPadding: '10px 0 10px 0',
    baseHeadingFontWeight: '500',
  },
  icons: {
    smaller: '8px',
    small: '12px',
    normal: '16px',
    large: '20px',
    larger: '30px',
  },
  spacing: {
    smaller: '8px',
    small: '12px',
    normal: '16px',
    large: '24px',
    larger: '32px',
  }
}

export default createGlobalStyle`
  body,
  html,
  #root {
    height: 100%;
    background-color: ${variables.color.lightGray};
  }

  html {
    height: 100%;
  }

  body {
    font-family: ${variables.typography.baseFontFamily}, sans-serif;

    textarea:focus,
    textarea.form-control:focus,
    input.form-control:focus,
    input[type=text]:focus,
    input[type=password]:focus,
    input[type=email]:focus,
    input[type=number]:focus,
    [type=text].form-control:focus,
    [type=password].form-control:focus,
    [type=email].form-control:focus,
    [type=tel].form-control:focus,
    [contenteditable].form-control:focus {
      box-shadow: inset 0 -1px 0 #ddd;
    }
  }
`