import React from 'react';
import FacebookLogo from 'Assets/fb.svg';
import InstagramLogo from 'Assets/instagram.svg';
import LinkedinLogo from 'Assets/linkedin.svg';
import { Translate } from 'Foundation/Translations';

export const items = [
  {
    name: 'partner',
    value: <Translate>translation:menu.footer.partner</Translate>,
    href: '/partner',
  },
  {
    name: 'about',
    value: <Translate>translation:menu.top.about</Translate>,
    href: '/about',
  },
  {
    name: 'faq',
    value: <Translate>translation:menu.top.faq</Translate>,
    href: '/faq',
  }
];

export const buttons = [
  {
    name: 'login',
    value: <Translate>translation:menu.top.logIn</Translate>,
    href: '/login',
    color: {
      primary: 'link-white',
      light: 'link-dark',
    }
  },
  {
    name: 'signup',
    value: <Translate>translation:menu.top.signUp</Translate>,
    href: '/signup',
    color: {
      primary: 'primary rounded-pill',
      light: 'primary rounded-pill',
    }
  }
];

export const footer = {
  left: [
    {
      name: 'codes',
      href: '/codes',
      value: <Translate>translation:menu.footer.codes</Translate>,
    },
    {
      name: 'about',
      href: '/about',
      value: <Translate>translation:menu.footer.about</Translate>,
    },
    {
      name: 'faq',
      href: '/faq',
      value: <Translate>translation:menu.footer.faq</Translate>,
    },
    // {
    //   name: 'mobile',
    //   href: '#',
    //   value: <Translate>translation:menu.footer.mobile</Translate>,
    // },
    // {
    //   name: 'web',
    //   href: '#',
    //   value: <Translate>translation:menu.footer.web</Translate>,
    // },
    // {
    //   name: 'extension',
    //   href: '#',
    //   value: <Translate>translation:menu.footer.extension</Translate>,
    // },
  ],
  right: [
    // {
    //   name: 'create',
    //   href: '/signup',
    //   value: <Translate>translation:menu.footer.create</Translate>,
    // },
    // {
    //   name: 'login',
    //   href: '/login',
    //   value: <Translate>translation:menu.footer.logIn</Translate>,
    // },
    {
      name: 'partner',
      href: '/partner',
      value: <Translate>translation:menu.footer.partner</Translate>,
    },
    {
      name: 'contact',
      href: '/contact',
      value: <Translate>translation:menu.footer.contact</Translate>,
    },
    {
      name: 'privacy',
      href: '/privacy',
      value: <Translate>translation:menu.footer.privacy</Translate>,
    },
  ],
  social: [
    {
      name: 'facebook',
      href: '#',
      alt: 'Discode on Facebook',
      imageUrl: FacebookLogo,
    },
    {
      name: 'instagram',
      href: '#',
      alt: 'Discode on Instagram',
      imageUrl: InstagramLogo,
    },
    {
      name: 'linkedin',
      href: '#',
      alt: 'Discode on LinkedIn',
      imageUrl: LinkedinLogo,
    },
  ]
};
