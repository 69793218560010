import { get, head, isEmpty, map, uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import CodesHeaderImg from 'Assets/codes-header.png';
import Button from 'Components/Button';
import CategoryMenu from 'Components/Category/Menu';
import Item from 'Components/Code/Item';
import UnexpectedError from 'Components/Errors/Unexpected';
import PopularCompanies from 'Components/Popular/Company';
import SearchInput from 'Components/Input/Search';
import Axios from 'Foundation/Axios';
import { normalizeCoupons, normalizeDeals } from 'Foundation/Normalizers';
import { HtmlTranslate, Translate } from 'Foundation/Translations';
import Layout from 'Templates/Layout/Main';
import { variables } from 'Utils/Styles';

const TopSection = styled.div`
  background-image: url(${CodesHeaderImg});
  background-size: cover;
  height: 600px;
  width: 100%;
  padding-top: 150px;
  margin-bottom: 70px;

  .banner-text {
    padding-top: 50px;
  }

  h1 {
    color: ${variables.color.white};
    font-weight: 600;
    font-size: 56px;
    margin-bottom: ${variables.spacing.larger};
  }

  h1 span {
    color: ${variables.color.primary};
    display: block;
  }
`;

const Wrapper = styled.div`
  h2 {
    color: ${variables.color.primary};
  }

  .col-md-3 {
    margin-bottom: 15px;
  }

  .row {
    margin-bottom: 70px;
  }

  .heading-description {
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${variables.typography.smallFontSize};
  }

  .filters {
    display: flex;
    margin-bottom: ${variables.spacing.normal};
    justify-content: space-between;
    max-width: 190px;
  }
`;

export default () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [codes, setCodes] = useState([]);
  const [deals, setDeals] = useState([]);
  const [filter, setFilter] = useState('coupons');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCodes = async () => {
      try {
          if (!loading) {
            setLoading(true);
          }

          const { data: { data, included }} = await Axios.get('/coupons?per_page=12');

          setCodes(normalizeCoupons(data, included));
          setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
        addToast(
          <Translate>notifications:general.error</Translate>,
          { appearance: 'error' },
        );
      }
    };

    const fetchDeals = async () => {
      try {
        if (!loading) {
          setLoading(true)
        }

        const { data: { data, included } } = await Axios.get(`deals?public=1&per_page=12`);
        setDeals(normalizeDeals(data, included));
        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
        addToast(
          <Translate>notifications:general.error</Translate>,
          { appearance: 'error' },
        );
      }
    };

    if (filter === 'coupons') {
      fetchCodes();
      return;
    }

    if (filter === 'deals') {
      fetchDeals();
      return;
    }

    setError(true);
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCoupons = () => {
    if (filter !== 'coupons') {
      return null;
    }

    if (isEmpty(codes) && loading) {
      return <Spinner color='secondary' />;
    }

    return map(codes, ({ name, code, comments, highlighted, categories, company }) => (
      <Col md='3' key={uniqueId()}>
        <Item
          name={get(head(categories), 'name', '')}
          highlighted={highlighted}
          heading={name}
          content={comments || ''}
          companyName={company.name}
          color='darkBlue'
          code={code}
          imageUrl={company.logo1}
        />
      </Col>
    ));
  }

  const renderDeals = () => {
    if (filter !== 'deals') {
      return null;
    }

    if (isEmpty(deals) && loading) {
      return <Spinner color='secondary' />;
    }

    return map(deals, ({ heading, description, categories, company, referralUrl }) => (
      <Col md='3' key={uniqueId()}>
        <Item
          name={get(head(categories), 'name', 'other')}
          highlighted={false}
          heading={heading}
          content={description || ''}
          companyName={company.name}
          imageUrl={company.logo1}
          color='darkBlue'
          badgeContent={<Translate>translation:entities.deal</Translate>}
          isDeal
          referralUrl={referralUrl}
        />
      </Col>
    ));
  }

  if (error) {
    return <UnexpectedError />;
  }

  return (
    <Layout
      metaData={{
        title: t('seo:pages.codes.title'),
        description: t('seo:pages.codes.description'),
      }}
    >
      <TopSection>
        <Container>
          <Row>
            <Col sm='6' className='banner-text offset-sm-6'>
              <h1><HtmlTranslate>translation:homepage.saveMoney</HtmlTranslate></h1>
              <SearchInput placeholder={t('translation:forms.placeholders.shop')}/>
            </Col>
          </Row>
        </Container>
      </TopSection>
      <Wrapper>
        <Container>
          <h2>Find your discount codes & deals</h2>
          <div className='filters'>
            <Button
              onClick={() => setFilter('coupons')}
              color='social'
              className={`uppercase ${filter === 'coupons' ? 'pink' : ''}`}
            >
              <Translate>translation:search.button.coupons</Translate>
            </Button>
            <Button
              onClick={() => setFilter('deals')}
              color='social'
              className={`uppercase ${filter === 'deals' ? 'pink' : ''}`}
            >
              <Translate>translation:search.button.deals</Translate>
            </Button>
          </div>
          <Row>
            {renderCoupons()}
            {renderDeals()}
          </Row>
          <p className='heading-description'>
            <Translate>coupons:category.search</Translate>
          </p>
          <CategoryMenu color='darkBlue' size='larger' />
          <p className='heading-description'>
            <Translate>coupons:company.search</Translate>
          </p>
          <PopularCompanies includeHeading={false} />
        </Container>
      </Wrapper>
    </Layout>
  );
};
