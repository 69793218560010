import { isNil } from 'lodash';
import { string, number } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Translate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  h4 {
    padding-top: 15px;
  }

  h1 {
    margin: 50px 0px
  }

  h1:hover {
    text-decoration: none;
  }

  span {
    background-color: ${variables.color.pink};
    color: white;
    width: 40%;
    text-align: center;
    left: 14px;
    position: absolute;
    padding: 0px 10px;

    &.inactive {
      background-color: ${variables.color.mediumGray};
    }
  }
`;

const StyledLink = styled(Link)`
  color: #0f0e3e;

  :hover {
    text-decoration: none;
    color: #0f0e3e;
  }
`;

const ResultCard = ({ name, coupons, deals, logo, slug }) => {
  const getHeadline = () => {
    if (coupons > 0 && deals > 0) {
      return (
        <span>
          <Translate
            options={{
              couponsCount: coupons,
              dealsCount: deals,
            }}
          >
            translation:search.results.found.count.multiple
          </Translate>
        </span>
      );
    }

    if (coupons > 0 && deals === 0) {
      return (
        <span>
          <Translate options={{ count: coupons }}>
            translation:search.results.found.count.coupons
          </Translate>
        </span>
      );
    }

    if (deals > 0 && coupons === 0) {
      return (
        <span>
          <Translate options={{ count: deals }}>
            translation:search.results.found.count.deals
          </Translate>
        </span>
      );
    }

    return (
      <span className='inactive'>
        <Translate>translation:search.results.found.count.empty</Translate>
      </span>
    );
  };

  return (
    <StyledLink to={`/shop/${slug}`}>
      <Wrapper className='text-center'>
        {getHeadline()}
        {isNil(logo) ? <h1>{name}</h1> : <img src={logo} alt={name} />}
      </Wrapper>
      <h5>{name}</h5>
    </StyledLink>
  )
};

ResultCard.propTypes = {
  name: string.isRequired,
  slug: string.isRequired,
  coupons: number,
  deals: number,
  logo: string,
};

ResultCard.defaultProps = {
  coupons: 0,
  deals: 0,
  logo: null,
};

export default ResultCard;
