import React from 'react';
import { string, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

export const Translate = ({ children, options }) => {
  const { t } = useTranslation();

  return t(children, options);
};

Translate.propTypes = {
  options: shape(),
  children: string.isRequired,
};

Translate.defaultProps = {
  options: {},
};

Translate.defaultProps = {
  options: {},
};

export const HtmlTranslate = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div dangerouslySetInnerHTML={{__html: t(children)}} />
  )
};

HtmlTranslate.propTypes = {
  children: string.isRequired,
};