import { isNil } from 'lodash';
import { shape } from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications'
import Axios from 'Foundation/Axios';
import { Translate } from 'Foundation/Translations';

const EmailVerify = ({ history, location }) => {
  const { addToast } = useToasts();
  const token = (new URLSearchParams(location.search)).get('token');

  useEffect(() => {
    const activate = async () => {
      if (isNil(token)) {
        return;
      }

      try {
        await Axios.post('users/verify', { token });
      } catch (err) {
        addToast(
          <Translate>notifications:general.error</Translate>,
          { appearance: 'error' },
        );
      }
    };

    activate();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isNil(token)) {
    history.push('/');
  }

  return (
    <>
      <h1>Thank you for confirming your email</h1>
      <p>Your email was successfully confirmed.</p>
      <p><a href='/dashboard'>Go to your dashboard</a></p>
    </>
  );
};

EmailVerify.propTypes = {
  history: shape().isRequired,
  location: shape().isRequired,
}

export default withRouter(EmailVerify);
