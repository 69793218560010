import { find, isNil, map } from 'lodash';
import moment from 'moment';

export const normalizeCategory = ({
  id,
  attributes: {
    name,
  }
}) => ({
  id,
  name,
})

export const normalizeCompany = ({
  id,
  attributes: {
    name,
    logo_1: logo1,
    logo_2: logo2,
    total_coupons: couponsCount,
    total_deals: dealsCount,
    description,
    slug,
  },
}) => ({
  id,
  name,
  logo1,
  logo2,
  couponsCount,
  dealsCount,
  description,
  slug,
});

export const normalizeCompanies = raw => map(raw, normalizeCompany);

export const normalizeCoupon = (
  {
    id,
    attributes: {
      code,
      comments,
      expires_at: expiresAt,
      name,
      public: isPublic,
      under_review,
      company_id: companyId,
      categories,
    },
  },
  relations,
) => ({
  id,
  code,
  name,
  categories,
  comments,
  expiresAt: isNil(expiresAt) ? null : moment.utc(expiresAt),
  isPublic,
  isUnderReview: under_review,
  company: normalizeCompany(find(relations, { id: companyId.toString() })),
});

export const normalizeCategories = raw => map(
  raw,
  normalizeCategory,
);

export const normalizeCoupons = (raw, relations) => map(raw, item => normalizeCoupon(item, relations))

export const normalizeDeal = (
  {
    id,
    attributes: {
      heading,
      description,
      expires_at: expiresAt,
      referral_url: referralUrl,
      company_id: companyId,
    },
  },
  relations,
) => ({
  id,
  heading,
  description,
  expiresAt: isNil(expiresAt) ? null : moment.utc(expiresAt),
  referralUrl,
  company: normalizeCompany(find(relations, { id: companyId.toString() })),
});

export const normalizeDeals = (raw, relations) => map(raw, item => normalizeDeal(item, relations));
