import { func } from 'prop-types';
import React from 'react';
import {
  BrowserRouter as Router,
  Route as RouterRoute,
  Switch,
} from 'react-router-dom';
import Loading from 'Components/Loading';
import NotFound from 'Components/Errors/NotFound';
import { AuthConsumer } from 'Foundation/Authentication';
// import AddCode from 'Pages/AddCode';
import Home from 'Pages/Home';
import About from 'Pages/About';
import Codes from 'Pages/Codes';
import Company from 'Pages/Company';
import Contact from 'Pages/Contact';
// import MyCodes from 'Pages/Codes/Private';
import Faq from 'Pages/Faq';
// import Login from 'Pages/Login';
// import SignUp from 'Pages/SignUp';
// import Dashboard from 'Pages/Dashboard';
import PasswordReset from 'Pages/PasswordReset';
import EmailVerify from 'Pages/EmailVerify';
import Search from 'Pages/Search';
import Privacy from 'Pages/Privacy';
import Partner from 'Pages/Partner';
// import ProtectedRoute from 'Foundation/Authentication/ProtectedRoute';
import ScrollToTop from 'Foundation/ScrollToTop';

const Route = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ loading }) => {
      if (loading) {
        return <Loading />;
      }

      return (
        <RouterRoute {...rest}>
          <Component />
        </RouterRoute>
      );
    }}

  </AuthConsumer>
);

Route.propTypes = {
  component: func.isRequired,
};

const Routes = () => (
  <Switch>
    <Route component={Home} path='/' exact />
    <Route component={About} path='/about' />
    {/* <Route component={Login} path='/login' />
    <Route component={SignUp} path='/signup' /> */}
    <Route component={Faq} path='/faq' />
    <Route component={Search} path='/search' />
    <Route component={PasswordReset} path='/password-reset' />
    <Route component={EmailVerify} path='/email/verify' />
    <Route component={Company} path='/shop/:name' />
    <Route component={Privacy} path='/privacy' />
    <Route component={Partner} path='/partner' />
    <Route component={Contact} path='/contact' />
    {/* <ProtectedRoute component={MyCodes} path='/codes/personal' />
    <ProtectedRoute component={AddCode} path='/codes/add' /> */}
    <Route component={Codes} path='/codes' />
    {/* <ProtectedRoute component={Dashboard} path='/dashboard' /> */}
    <Route component={NotFound} />
  </Switch>
);

const router = () => (
  <Router>
    <>
      <ScrollToTop />
      <Routes />
    </>
  </Router>
);

export default router;