import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import { variables } from 'Utils/Styles';

const Item = styled.a`
  padding: ${variables.spacing.normal};
  display: block;
  color: ${variables.color.darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    text-decoration: none;
    color: ${variables.color.darkBlue};
  }

  img {
    margin-bottom: ${variables.spacing.smaller};
  }
`;

const CategoryItem = ({ url, name, color, size }) => (
  <Item href={url}>
    <Icon name={name} color={color} size={size} />
    <p>{name}</p>
  </Item>
);

CategoryItem.propTypes = {
  url: string.isRequired,
  name: string.isRequired,
  color: string,
  size: string.isRequired,
}

CategoryItem.defaultProps = {
  color: variables.color.darkBlue,
};

export default CategoryItem;
