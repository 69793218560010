import { Cancel } from 'axios'
import { isEmpty } from 'lodash'
import React, { createContext, useEffect, useState } from 'react'
import {
  events,
  getTokens,
  LOCALSTORAGE_KEY,
  login,
  logout,
  socialLogin,
} from 'Foundation/Authentication/OAuth2'
import instance from 'Foundation/Axios'

const defaultState = {
  loading: true,
  user: {},
}

export const AuthContext = createContext(null)
export const AuthConsumer = AuthContext.Consumer

export const AuthProvider = ({ children }) => {
  const [{ loading, refreshToken, user }, setState] = useState({
    ...defaultState,
    ...getTokens(),
  })

  const fetchMe = async () => {
    try {
      const result = await instance.get('/me')
      setState(prev => ({ ...prev, loading: false, user: result.data.data }))
    } catch (err) {
      // Request was cancelled, most likely due to token being refreshed
      if (err instanceof Cancel) {
        return
      }

      setState(prev => ({ ...prev, loading: false }))
    }
  }

  // Fetch user information when tokens change
  useEffect(() => {
    if (!isEmpty(refreshToken)) {
      console.log('new refresh token, check who we are')
      fetchMe() // TODO: Handle endless loop if /me continously returns 401
      return
    }

    console.log('refresh token removed, remove user info')
    setState(prev => ({ ...prev, loading: false, user: {} }))
  }, [refreshToken])

  // Handle token changes
  useEffect(() => {
    const handle = ({ accessToken, refreshToken }) => setState(prev => {
      if (prev.accessToken === accessToken && prev.refreshToken === refreshToken) {
        return prev
      }

      return ({ ...prev, accessToken, refreshToken })
    })

    events.on(LOCALSTORAGE_KEY, handle)
    return () => events.off(LOCALSTORAGE_KEY, handle)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loading,
        login,
        logout,
        user,
        socialLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
