import { isNil, noop } from 'lodash';
import { bool, func, string, node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import { Translate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  padding: ${variables.spacing.large};
  text-align: center;
  margin-bottom: 32px;
  position: relative;

  .inner {
    position: relative;
    height: 352px;
  }

  .item-badge {
    position: absolute;
    background-color: #f66483;
    top: 10px;
    left: 0;
    padding: 4px 8px;
    color: #fff;
    font-size: 14px;
  }

  .item-badge-disabled {
    opacity: 0.3;
  }

  .btn-primary-light {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
  }

  &.highlighted {
    color: ${variables.color.white};
    background: ${variables.color.primary};
    background: ${variables.color.primaryGradient};
  }

  .comment {
    font-size: ${variables.typography.baseFontSize};
    padding: 0 10px 0 0;
  }

  .company-logo {
    height: 54px;
    margin-bottom: ${variables.spacing.large};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 54px;
      display: block;
      margin: 0 auto 0 auto;
    }

    h3 {
      display: flex;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .heading {
    font-size: 22px;
    letter-spacing: 2px;
  }

  .circle-icon {
    display: block;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: ${variables.spacing.smaller};
    background-color: ${variables.color.gray};
    margin: 0 auto ${variables.spacing.large} auto;

    img {
      display: block;
    }
  }

  ${props => props.layout === 'row'
    ? `
      @media (min-width: 768px) {
        .inner {
          display: flex;
          flex-direction: row;
          height: auto;
          align-items: center;
          justify-content: space-between;
        }

        .item-text {
          text-align: left;
          padding-left: 120px;
        }

        .btn-primary-light {
          max-width: 180px;
          position: relative;
          height: 40px;
        }

        .circle-icon {
          display: none;
        }
      }
    `
    : ''}
`;

const Code = ({
  code,
  color,
  companyName,
  content,
  heading,
  highlighted,
  imageUrl,
  name,
  onClick,
  showCode,
  layout,
  showLogo,
  badgeContent,
  isDeal,
  referralUrl,
}) => {
  return (
    <Wrapper layout={layout} className={highlighted ? 'highlighted' : ''}>
      {!isNil(badgeContent) && (
        <div className='item-badge'>
          {badgeContent}
        </div>
      )}
      <div className='inner'>
        <div className='circle-icon'>
          <Icon name={name} color={color} />
        </div>
        {showLogo && (
          <div className='company-logo'>
            {isNil(imageUrl) ? <h3>{companyName}</h3> : <img src={imageUrl} alt={companyName} />}
          </div>
        )}
        <div className="item-text">
          <p className='heading'>{heading}</p>
          <div className='comment'>{content}</div>
        </div>
        {!isDeal && (
          <Button color='primary-light' onClick={onClick}>
            {showCode ? code : <Translate>coupons:coupon.reveal</Translate>}
          </Button>
        )}
        {isDeal && (
          <Button color='primary-light' onClick={onClick}>
            <Translate>deals:deal.buttons.reveal</Translate>
          </Button>
        )}
      </div>
    </Wrapper>
  )
};

Code.propTypes = {
  code: string,
  color: string.isRequired,
  companyName: string.isRequired,
  content: string.isRequired,
  heading: string.isRequired,
  highlighted: bool,
  imageUrl: string,
  name: string.isRequired,
  onClick: func,
  showCode: bool,
  layout: string,
  showLogo: bool,
  badgeContent: node,
  isDeal: bool,
  referralUrl: string,
};

Code.defaultProps = {
  code: null,
  highlighted: false,
  imageUrl: null,
  onClick: noop,
  showCode: false,
  layout: 'grid',
  showLogo: true,
  badgeContent: null,
  isDeal: false,
  referralUrl: null,
};

export default Code;
