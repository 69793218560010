
import { noop } from 'lodash';
import { func, string } from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import { variables } from 'Utils/Styles';

const Wrapper = styled(InputGroup)`
  max-width: 300px;
  margin-bottom: ${variables.spacing.large};

  .btn-primary {
    padding-left: ${variables.spacing.larger};
    padding-right: ${variables.spacing.larger};
  }
`;

const SearchInput = ({ placeholder }) => {
  const history = useHistory();
  const [query, setQuery] = useState(null);

  return (
    <Wrapper>
      <Input
        placeholder={placeholder}
        onChange={(event) => setQuery(event.target.value || '')}
        onKeyPress={(event) => {
          if (event.key !== 'Enter') {
            return;
          }

          history.push(`/search?q=${query}`);
        }}
      />
      <InputGroupAddon addonType='append'>
        <Button color='primary' onClick={() => history.push(`/search?q=${query}`)}>
          <Icon name='search' color='white' />
        </Button>
      </InputGroupAddon>
    </Wrapper>
  );
};

SearchInput.propTypes = {
  placeholder: string.isRequired,
  onSubmit: func,
};

SearchInput.defaultProps = {
  onSubmit: noop,
};

export default SearchInput;
