import React from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  max-width: 600px;
  color: #fff;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: perspective(1px) translateY(-50%) translateX(-50%);
  -ms-transform: perspective(1px) translateY(-50%) translateX(-50%);
  transform: perspective(1px) translateY(-50%) translateX(-50%);
  left: 50%;
`;

const Loading = () => (
  <Wrapper>
    <div>
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="secondary" />
      <Spinner type="grow" color="success" />
      <Spinner type="grow" color="danger" />
      <Spinner type="grow" color="warning" />
      <Spinner type="grow" color="info" />
      <Spinner type="grow" color="light" />
      <Spinner type="grow" color="dark" />
    </div>
    <h3>Loading</h3>
  </Wrapper>
);

export default Loading;
