import { node, shape, string } from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Footer from 'Components/Footer';
import Menu from 'Components/Menu';
import { variables } from 'Utils/Styles';

const HeaderWrapper = styled.header`
  padding: 10px 0 10px 0;
  position: static;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-out;
  background-color: ${variables.color.darkBlue};
  z-index: 100;

  .navbar-brand {
    width: 165px;
  }

  .navbar {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  background-color: #f8f8f8;
`;

const FooterWrapper = styled.div`
  background-color: ${variables.color.darkBlue};
  padding: 30px 0 30px 0;
`;

const SecondaryLayout = ({ children, metaData }) => (
  <>
    <Helmet>
      <title>{metaData.title}</title>
      <meta name='description' content={metaData.description} />
    </Helmet>
    <HeaderWrapper>
      <Menu />
    </HeaderWrapper>
    <Wrapper>
      {children}
    </Wrapper>
    <FooterWrapper>
      <Footer />
    </FooterWrapper>
  </>
);

SecondaryLayout.defaultProps = {
  children: node.isRequired,
  metaData: shape({
    title: string.isRequired,
    description: string.isRequired,
  }).isRequired,
};

export default SecondaryLayout;
