import { map } from 'lodash';
import React from 'react';
import {
  Container,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import styled from 'styled-components';
import WhiteLogo from 'Assets/logo-white.svg';
import { footer } from 'Utils/Menu';

const Logo = styled.img`
  height: 48px;
  margin-bottom: 32px;
`

const FooterNav = styled(Nav)`
  margin: 0 auto;

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }

  .nav-item {
    .nav-link {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 2px;
      font-weight: 300;
      padding: 0.3rem 0;
    }
  }
`

const SocialMediaLink = styled(Link)`
  padding: 7px;
`;

const Wrapper = styled(Container)`
  @media (max-width: 767px) {
    padding: 0 32px;
  }
`;

const Footer = () =>{
  return (
    <Wrapper>
      <Row>
        <Col md='3'>
          <Link to='#'>
            <Logo src={WhiteLogo} alt='Logo' />
          </Link>
        </Col>
        <Col md='3'>
          <FooterNav vertical>
            {map(footer.left, ({ name, href, value }) => (
              <NavItem key={name}>
                <NavLink tag={RRNavLink} to={href}>
                  {value}
                </NavLink>
              </NavItem>
            ))}
          </FooterNav>
        </Col>
        <Col md='3'>
          <FooterNav vertical>
            {map(footer.right, ({ name, href, value }) => (
              <NavItem key={name}>
                <NavLink tag={RRNavLink} to={href}>
                  {value}
                </NavLink>
              </NavItem>
            ))}
          </FooterNav>
        </Col>
        <Col md='3'>
          {map(footer.social, ({ name, href, alt, imageUrl }) => (
            <SocialMediaLink to={href} key={name}>
              <img src={imageUrl} alt={alt}/>
            </SocialMediaLink>
          ))}
        </Col>
      </Row>
    </Wrapper>
  )
};

export default Footer;
