import { Button as BootstrapButton } from 'reactstrap';
import styled from 'styled-components';
import { variables } from 'Utils/Styles';

const StyledButton = styled(BootstrapButton)`
  border-radius: ${props => props.rounded  ? '20px' : '4px'};

  &.btn-primary {
    background: rgb(248,181,161);
    background: linear-gradient(180deg, rgba(248,181,161,1) 0%, rgba(248,151,151,1) 100%);
    border: none;
    padding: 8px 18px;
    text-transform: uppercase;
    font-size: ${variables.typography.baseFontSize};
    letter-spacing: 0.5px;
    font-weight: 700;
  }

  &.btn-white {
    background-color: ${variables.color.white};
    color: ${variables.color.primary};
    text-transform: uppercase;
    font-weight: 700;
    border-color: ${variables.color.white};
    font-size: ${variables.typography.baseFontSize};
    letter-spacing: 0.5px;
    padding: 8px 18px;
  }

  &.btn-link-white {
    color: ${variables.color.white};
  }

  &.btn-link-pink {
    color: ${variables.color.pink};
    padding: 0;
  }

  &.btn-link-dark {
    color: ${variables.color.darkBlue};
    padding: 8px 18px;
  }

  &.btn-primary-light {
    background-color: ${variables.color.primaryLight};
    color: ${variables.color.darkBlue};
    letter-spacing: 2px;
  }

  &.btn-primary-darkblue {
    background-color: ${variables.color.white};
    color: ${variables.color.darkBlue};
    letter-spacing: 2px;
    width: 150px;
    border-radius: 16px;
    padding: 5px;
    text-transform: uppercase;
    font-size: ${variables.typography.smallFontSize};
  }

  &.btn-social {
    background-color: ${variables.color.darkBlue};
    color: ${variables.color.white};
    padding: 5px 15px;
    border-radius: 20px;
    letter-spacing: 0.8px;
    font-size: 12px;

    @media (min-width: 1200px) {
      font-size: ${variables.typography.baseFontSize};
    }
  }

  &.btn-transparent-white {
    background-color: transparent;
    border-color: ${variables.color.white};
    color: ${variables.color.white};
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 5px 50px 5px 50px;
    border-radius: 20px;

    :hover {
      background-color: ${variables.color.white};
      color: ${variables.color.darkBlue};
    }
  }

  &.btn-primary-pink {
    background-color: ${variables.color.pink};
    color: ${variables.color.white};
    letter-spacing: 2.5px;
    font-size: ${variables.typography.baseFontSize};
    text-transform: uppercase;
    padding: 5px 50px 5px 50px;
    border-radius: 20px;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.pink {
    background-color: ${variables.color.primary};
  }
`

export default StyledButton;