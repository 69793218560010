import { string } from 'prop-types';
import { map, uniqueId } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import CategoryItem from 'Components/Category/Item';
import { categories } from 'Utils/Categories';

const Wrapper = styled.div``;

const CategoryMenu = ({ color, size }) => (
  <Wrapper>
    <Row>
      {map(categories, ({ name, url }) => (
        <Col key={uniqueId()} className='text-center' xs='4' sm='2'>
          <CategoryItem url={url} name={name} color={color} size={size} />
        </Col>
      ))}
    </Row>
  </Wrapper>
);

CategoryMenu.propTypes = {
  color: string.isRequired,
  size: string.isRequired,
}

export default CategoryMenu;
