import { map, uniqueId } from 'lodash';
import React from 'react';
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PrivacyPolicyImg from 'Assets/privacypolicy.svg';
import { Translate } from 'Foundation/Translations';
import Layout from 'Templates/Layout/Main';
import { variables } from 'Utils/Styles';

const HeadlineWrapper = styled.div`
  padding: 50px 0;
  margin-top: 60px;
  background-color: ${variables.color.lightGray};

  img {
    max-width: 100%;
  }

  .banner-text {
    h1 {
      font-weight: 600;
      color: ${variables.color.darkBlue};
    }

    p {
      color: ${variables.color.lightBlue};
      font-weight: 500;
      font-size: ${variables.typography.baseFontFamily};
      letter-spacing: ${variables.typography.baseLetterSpacing};
    }

    .privacy-description {
      width: 90%;
    }

    @media (max-width: 767px) {
      margin-bottom: ${variables.spacing.smaller};

      h1 {
        font-size: 28px;
      }

      .privacy-description {
        width: 100%;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  margin-top: ${variables.spacing.larger};
  padding-bottom: ${variables.spacing.larger};
`;

const ItemsNav = styled(Nav)`
  margin: 0 auto;

  .nav-item {
    padding: ${variables.spacing.small};
    border-top: 1px solid ${variables.color.mediumGray};

    &:first-child {
      border-top: none;
    }

    .nav-link {
      color: ${variables.color.darkBlue};
      font-size: 18px;
      letter-spacing: ${variables.typography.baseLetterSpacing};
      font-weight: 400;
    }
  }
`;

const PrivacyItem = styled.div`
  margin-top: ${variables.spacing.large};
  border-bottom: 1px solid ${variables.color.mediumGray};

  h3 {
    font-weight: 600;
    color: ${variables.color.darkBlue};
  }

  p {
    color: ${variables.color.lightBlue};
    font-weight: 500;
    font-size: 18px;
    letter-spacing: ${variables.typography.baseLetterSpacing};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Privacy = () => {
  const { t } = useTranslation();

  const privacyItems = [
    'introduction',
    'collect',
    'share',
    'protect',
    'cookies',
    'manage',
    'additional',
    'gdpr',
    'minors',
    'contact',
  ];

  return (
    <Layout
      menuType='light'
      metaData={{
        title: t('seo:pages.privacy.title'),
        description: t('seo:pages.privacy.description'),
      }}
    >
      <>
        <HeadlineWrapper>
          <Container>
            <Row>
              <Col md='6' className='banner-text align-self-center'>
                <h1>
                  <Translate>translation:companyName</Translate>
                </h1>
                <h1>
                  <Translate>translation:privacy.headline</Translate>
                </h1>
                <div className='privacy-description'>
                  <p>
                    <Translate>translation:privacy.privacyDescription</Translate>
                  </p>
                </div>
              </Col>
              <Col md='6' className='align-self-center text-center'>
                <img src={PrivacyPolicyImg} alt={t('translation:privacy.privacyIllustration')} />
              </Col>
            </Row>
          </Container>
        </HeadlineWrapper>
        <ContentWrapper>
          <Container>
            <Row>
              <Col md='4'>
                <ItemsNav vertical>
                  {map(privacyItems, item => (
                    <NavItem key={uniqueId()}>
                      <NavLink href={`#${item}`}>
                        <Translate>{`translation:privacy.items.${item}.headline`}</Translate>
                      </NavLink>
                    </NavItem>
                  ))}
                </ItemsNav>
              </Col>
              <Col md='8'>
                {map(privacyItems, item => (
                  <PrivacyItem id={item} key={uniqueId()}>
                    <h3>
                      <Translate>{`translation:privacy.items.${item}.headline`}</Translate>
                    </h3>
                    <p>
                      <Translate>{`translation:privacy.items.${item}.description`}</Translate>
                    </p>
                  </PrivacyItem>
                ))}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </>
    </Layout>
  );
};

export default Privacy;
