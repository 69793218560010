import { map } from 'lodash';
import { bool, string, shape } from 'prop-types';
import React, { useState } from 'react';
import {
  Link,
  NavLink as RRNavLink,
  withRouter,
} from 'react-router-dom';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import styled from 'styled-components';
import DarkLogo from 'Assets/logo-dark.svg';
import WhiteLogo from 'Assets/logo-white.svg';
import Button from 'Components/Button';
// import { AuthContext } from 'Foundation/Authentication';
import { Translate } from 'Foundation/Translations';
import { items } from 'Utils/Menu';
import { variables } from 'Utils/Styles';

const Logo = styled.img`
  height: ${props => props.isSticky ? '38px' : '48px'};
  transition: all 0.3s ease-out;
`

const DcNav = styled(Nav)`
  margin: 0 auto;

  .nav-item {
    margin: 0 15px 0 15px;
    border-bottom: 1px solid transparent;
    transition: border 0.3s ease-in-out;

    &.active, &:hover {
      border-bottom-color: ${props => props.type === 'light' ? variables.color.darkBlue : variables.color.white};
    }

    .nav-link {
      color: ${props => props.type === 'light' ? variables.color.darkBlue : variables.color.white};

      :hover {
        color: ${props => props.type === 'light' ? variables.color.darkBlue : variables.color.white};
      }

      &.active {
        color: ${props => props.type === 'light' ? variables.color.darkBlue : variables.color.white};
      }
    }
  }
`

const ButtonsWrapper = styled.div`
`

const Menu = ({ isSticky, menuType, history }) => {
  // const { user, logout } = useContext(AuthContext)
  const { location: { pathname }} = history
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const logos = {
    light: DarkLogo,
    primary: WhiteLogo,
  }

  return (
    <Container>
      <Navbar light expand='md'>
        <NavbarBrand tag={Link} to='/'>
          <Logo src={logos[menuType]} isSticky={isSticky} alt='Logo' />
        </NavbarBrand>
        <NavbarToggler onClick={toggle}/>
        <Collapse isOpen={isOpen} navbar>
          <DcNav type={menuType} className='mr-auto' navbar>
            {map(items, ({ name, value, href }) => (
              <NavItem key={name} active={pathname === href}>
                <NavLink tag={RRNavLink} to={href}>
                  {value}
                </NavLink>
              </NavItem>
            ))}
          </DcNav>
          <ButtonsWrapper>
            {/* {!isEmpty(user) && <span>Hello {user.attributes.first_name}</span>}
            {!isEmpty(user) && <Button onClick={() => logout()} color='primary rounded-pill'>Logout</Button>}
            {isEmpty(user) && map(buttons, ({ name, value, href, color }) => (
              <Link to={href} key={name}>
                <Button color={color[menuType]}>
                  {value}
                </Button>
              </Link>
            ))} */}
            <Link to='/codes'>
              <Button color='primary rounded-pill'>
                <Translate>translation:menu.top.all</Translate>
              </Button>
            </Link>
          </ButtonsWrapper>
        </Collapse>
      </Navbar>
    </Container>
  )
};

Menu.propTypes = {
  isSticky: bool,
  menuType: string,
  history: shape().isRequired,
}

Menu.defaultProps = {
  isSticky: true,
  menuType: 'primary',
}

export default withRouter(Menu);
