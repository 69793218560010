import { get, head, isEmpty, isNil, map, slice, take, uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CategoryItem from 'Components/Category/Item';
import Item from 'Components/Code/Item';
import Loading from 'Components/Loading';
import Save from 'Components/HomePage/Save';
import Search from 'Components/Input/Search';
import NotFound from 'Components/Errors/NotFound';
import UnexpectedError from 'Components/Errors/Unexpected';
import Axios from 'Foundation/Axios';
import { normalizeCompany, normalizeCoupons, normalizeDeals } from 'Foundation/Normalizers';
import { Translate } from 'Foundation/Translations';
import Layout from 'Templates/Layout/Secondary';
import { categories } from 'Utils/Categories';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  background-color: white;
  padding-top: 20px;
  margin-bottom: 40px;

  .category-menu {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
    }

    @media (max-width: 767px) {
      li {
        width: 33%;
      }
    }
  }

  .top-wrapper {
    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .categories {
      padding-bottom: 20px
    }

    .company-search {
      padding-bottom: 20px;
    }

    @media (min-width: 768px) {
      .categories {
        float: left;
        padding-bottom: 0;
      }

      .company-search {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 0;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;

    .sidebar {
      flex: 0 0 260px;
      margin-right: 30px;
    }

    .main {
      flex: 1;
    }
  }
`;

const Widget = styled.div`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background-color: white;
  margin-bottom: 25px;
  padding: 15px 20px;

  h6 {
    text-transform: uppercase;
    font-weight: 600;
    color: ${variables.color.darkGray};
  }
`;

const LogoWrapper = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const initialState = {
  loading: true,
  company: null,
  error: false,
  codes: [],
  deals: [],
};

const Company = () => {
  const [{ loading, company, error, codes, deals }, setState] = useState(initialState);
  const { name } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    let isMounted = true;

    const fetchCompany = async () => {
      try {
        const { data: { data } } = await Axios.get(`companies?slug=${name}`);

        if (!isMounted) {
          return;
        }

        setState(prevState => ({
          ...prevState,
          loading: false,
          company: isEmpty(data) ? null : normalizeCompany(head(data)),
        }));
      } catch (err) {
        setState(prevState => ({
          ...prevState,
          error: true,
          company: null,
          loading: false,
          codes: [],
        }));
      }
    };

    fetchCompany();

    return () => {
      isMounted = false;
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isMounted = true;

    if (isNil(company)) {
      return;
    }

    const fetchDeals = async () => {
      try {
        if (!loading && isMounted) {
          setState(prevState => ({ ...prevState, loading: true }));
        }

        const { data: { data, included } } = await Axios.get(`deals?company_id=${company.id}&public=1`);

        if (!isMounted) {
          return;
        }

        setState(prevState => ({
          ...prevState,
          loading: false,
          deals: isEmpty(data) ? [] : normalizeDeals(data, included),
        }));
      } catch (err) {
        setState(prevState => ({
          ...prevState,
          error: true,
          loading: false,
          codes: [],
        }));
      }
    }

    const fetchCodes = async () => {
      try {
        if (!loading && isMounted) {
          setState(prevState => ({ ...prevState, loading: true }));
        }

        const { data: { data, included } } = await Axios.get(`coupons?company_id=${company.id}&public=1`);

        if (!isMounted) {
          return;
        }

        setState(prevState => ({
          ...prevState,
          loading: false,
          codes: isEmpty(data) ? [] : normalizeCoupons(data, included),
        }));
      } catch (err) {
        setState(prevState => ({
          ...prevState,
          error: true,
          loading: false,
          codes: [],
        }));
      }
    };

    fetchCodes();
    fetchDeals();

    return () => {
      isMounted = false;
    }
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <UnexpectedError />;
  }

  if (loading && isNil(company)) {
    return <Loading />;
  }

  if (!loading && isNil(company)) {
    return <NotFound />;
  }

  const { name: companyName, description, logo1 } = company;

  const getCodes = () => {
    if (loading || isEmpty(codes)) {
      return null;
    }

    const highlighted = take(codes, 3);
    const rest = slice(codes, 3);

    return (
      <>
        <Row>
          {map(highlighted, ({ name, code, comments, categories, expiresAt }) => (
            <Col sm='6' md='4' key={uniqueId()}>
              <Item
                name={get(head(categories), 'name', 'other')}
                highlighted={false}
                heading={name}
                content={comments || ''}
                companyName={companyName}
                color='darkBlue'
                code={code}
                showLogo={false}
                badgeContent={expiresAt.fromNow()}
              />
            </Col>
          ))}
        </Row>
        {map(rest, ({ name, code, comments, categories }) => (
          <Item
            key={uniqueId()}
            name={get(head(categories), 'name', 'other')}
            highlighted={false}
            heading={name}
            content={comments || ''}
            companyName={companyName}
            color='darkBlue'
            code={code}
            showLogo={false}
            layout='row'
            badgeContent={<Translate>translation:entities.coupon</Translate>}
          />
        ))}
      </>
    );
  };

  const getDeals = () => {
    if (loading || isEmpty(deals)) {
      return null;
    }

    return map(deals, ({ heading, description, categories, referralUrl }) => (
      <Item
        key={uniqueId()}
        name={get(head(categories), 'name', 'other')}
        highlighted={false}
        heading={heading}
        content={description || ''}
        companyName={companyName}
        color='darkBlue'
        showLogo={false}
        layout='row'
        badgeContent={<Translate>translation:entities.deal</Translate>}
        isDeal
        referralUrl={referralUrl}
      />
    ))
  };

  return (
    <Layout
      metaData={{
        title: t('seo:pages.company.title', { value: company.name }),
        description: t('seo:pages.company.description'),
      }}
    >
      <Wrapper>
        <Container>
          <div className="top-wrapper">
            <div className="categories">
              <h6>
                <Translate>coupons:category.search</Translate>
              </h6>
              <ul className="category-menu">
                {map(categories, ({ name: categoryName, url }, index) => (
                  <li key={index}>
                    <CategoryItem url={url} name={categoryName} size="larger" />
                  </li>
                ))}
              </ul>
            </div>
            <div className="company-search">
              <div>
                <h6>
                  <Translate>coupons:company.search</Translate>
                </h6>
                <Search placeholder={t('translation:forms.placeholders.shop')} />
              </div>
            </div>
          </div>
        </Container>
      </Wrapper>
      <Container>
        <ContentWrapper>
          <div className="sidebar">
            <Widget>
              <LogoWrapper>
                {isNil(logo1) ? <h1>{companyName}</h1> : <img src={logo1} alt={companyName} />}
              </LogoWrapper>
            </Widget>
            {false && (
              <Widget>
                <h6>
                  <Translate>companies:headline.similar</Translate>
                </h6>
              </Widget>
            )}
            <Widget>
              <h6>
                <Translate>translation:company.about</Translate>
              </h6>
              <p>
                <Translate>{description}</Translate>
              </p>
            </Widget>
          </div>
          <div className="main">
            {loading && isEmpty(codes) && (
              <div>
                <Spinner color='secondary' />
              </div>
            )}
            {!loading && isEmpty(codes) && (
              <div />
            )}
            {getCodes()}
            {getDeals()}
          </div>
        </ContentWrapper>
      </Container>
      <Save />
    </Layout>
  );
};

export default Company;
