import { has } from 'lodash';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Beauty from 'Assets/Icons/beauty.svg';
import Clothes from 'Assets/Icons/clothes.svg';
import Codes from 'Assets/Icons/codes.svg';
import Deals from 'Assets/Icons/deals.svg';
import Events from 'Assets/Icons/events.svg';
import Food from 'Assets/Icons/food.svg';
import Other from 'Assets/Icons/other.svg';
import Plus from 'Assets/Icons/plus.svg';
import Preferences from 'Assets/Icons/preferences.svg';
import Search from 'Assets/Icons/search.svg';
import Settings from 'Assets/Icons/settings.svg';
import SpecialOffers from 'Assets/Icons/special-offers.svg';
import Travel from 'Assets/Icons/travel.svg';
import { variables } from 'Utils/Styles';

const Image = styled.img`
  width: ${props => props.size ? variables.icons[props.size] : variables.icons.normal};
  color: ${props => props.color ? variables.color[props.color] : variables.color.darkBlue};
`

const Icon = ({ name, color, size }) => {
  const types = {
    beauty: Beauty,
    clothes: Clothes,
    codes: Codes,
    deals: Deals,
    events: Events,
    food: Food,
    other: Other,
    plus: Plus,
    preferences: Preferences,
    search: Search,
    settings: Settings,
    special: SpecialOffers,
    travel: Travel,
  }

  return (
    <Image
      src={has(types, name.toLowerCase()) ? types[name.toLowerCase()] : types.other}
      color={color}
      size={size}
    />
  );
};

Icon.propTypes = {
  name: string.isRequired,
  color: string,
  size: string,
};

Icon.defaultProps = {
  color: 'dark',
  size: 'normal',
}

export default Icon;
