import { isEmpty, isNil, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Spinner  } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NotFoundImg from 'Assets/shopnotfound.svg';
import Button from 'Components/Button';
import Loading from 'Components/Loading';
import CategoryMenu from 'Components/Category/Menu';
import SearchInput from 'Components/Input/Search';
import ResultCard from 'Components/ResultCard';
import PopularCodes from 'Components/Popular/Codes';
import PopularCompanies from 'Components/Popular/Company';
import Selector from 'Components/Selector';
import UnexpectedError from 'Components/Errors/Unexpected';
import Axios from 'Foundation/Axios';
import { normalizeCompanies } from 'Foundation/Normalizers';
import { Translate } from 'Foundation/Translations';
import Layout from 'Templates/Layout/Main';
import { categories } from 'Utils/Categories';
import { variables } from 'Utils/Styles';
import { string } from 'prop-types';

const NotFoundWrapper = styled.div`
  height: 620px;
  width: 100%;
  padding-top: 150px;
  background-color: ${variables.color.lightGray};
  @media (max-width: 767px) {
    height: auto;
    padding-top: 64px;
    margin-bottom: 0;
    background-image: none;
    background-color: ${variables.color.darkBlue};
  }

  h1 {
    color: ${variables.color.darkBlue};
    font-weight: 600;
    font-size: 56px;
    margin-bottom: ${variables.spacing.larger};

    @media (max-width: 767px) {
      font-size: 40px;
    }
  }

  p {
    color: ${variables.color.lightBlue};
  }

  .banner-text {
    padding-top: 50px;
    margin-top: 60px;
    @media (max-width: 767px) {
      padding-bottom: 50px;
      margin-top: 0px;
    }
  }
  .banner-text h1 span {
    color: black;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: ${variables.spacing.larger};

    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  .banner-text p {
    color: ${variables.color.black};
    font-size: 16px;
    margin-bottom: ${variables.spacing.larger};
    max-width: 300px;
    text-align: justify;

    @media (max-width: 767px) {
      font-size: 12px;
      color: ${variables.color.black};
    }
  }
`;

const HighlightsWrapper = styled.div`
  padding: 30px 0 30px 0;
  background: white;
  h2 {
    color: ${variables.color.primary};
  }

  .heading-description {
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${variables.typography.smallFontSize};
  }
`;

const CompanyWrapper = styled.div`
  background-color: #fff;

  .heading-description {
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${variables.typography.smallFontSize};
  }
`;

const Wrapper = styled.div`
  padding: 30px 0 30px 0;
`;

const ContentWrapper = styled.div`
  padding: 0 0 50px 0;
`;

const WrapperContent = styled.div`
  background-color: ${variables.color.lightGray};
`;

const Headline = styled.div`
  margin-top: 50px;

  .banner-text {
    padding-top: 50px;

    @media (max-width: 767px) {
      padding-bottom: 0px;
    }
  }

  .search_input .input-group {
    padding-top: 70px;
    float: right;

    @media (max-width: 767px) {
      float: initial;
      padding-top: 10px;
    }
  }

  .breadcrumbs {
    color: black;
    font-size: 15px;
    display: flex;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .companies_count {
    color: black;
    font-size: 30px;
    display: flex;
    margin-bottom: 10px;

    @media (max-width: 767px){
      font-size: 25px;
    }
  }
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${variables.spacing.normal};
`;

const NotFound = ({ query, category }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <NotFoundWrapper>
        <Container>
          <Row>
            <Col sm='6' className='banner-text'>
              <h1>
                <Translate>translation:search.results.notFound.headline</Translate>
              </h1>
              <p>
                <Translate options={{ query: query || category }}>translation:search.results.notFound.description</Translate>
              </p>
              <SearchInput placeholder={t('translation:search.placeholder')} />
              <Selector
                placeholder={t('translation:forms.category')}
                options={categories}
                onChange={({ value }) => history.push(`/search?category=${value}`)}
              />
            </Col>
            <Col sm='6' className='d-none d-sm-block text-right'>
              <img src={NotFoundImg} alt='Not found illustration' />
            </Col>
          </Row>
        </Container>
      </NotFoundWrapper>
      <HighlightsWrapper>
        <Container>
          <p className='heading-description'>
            <Translate>coupons:coupon.popularToday</Translate>
          </p>
          <PopularCodes color='darkBlue' />
        </Container>
      </HighlightsWrapper>
    </>
  );
};

NotFound.propTypes = {
  query: string,
  category: string,
};

NotFound.defaultProps = {
  query: null,
  category: null,
};

const initialState = {
  companies: [],
  error: false,
  loading: true,
  totalCompanies: 0,
};

let page = 1;
const Search = () => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(useLocation().search);
  const query = queryParams.get('q');
  const category = queryParams.get('category');
  const [isLoadingMore, setLoadingMore] = useState(false);

  const [{ companies, error, loading, totalCompanies }, setState] = useState(initialState);

  const fetchCompanies = async (refresh = false) => {
    try {
      if (isNil(query) && isNil(category)) {
        setState(prevState => ({ ...prevState, loading: false }));
        return;
      }

      const getUrl = () => {
        if (!isNil(category)) {
          return `/companies?category=${category}&page=${page}`;
        }

        return `/companies?q=${query}&page=${page}`;
      };

      const {
        data: {
          data,
          meta: {
            pagination: { total },
          },
        },
      } = await Axios.get(getUrl());

      const results = normalizeCompanies(data);
      setState(prevState => ({
        ...prevState,
        companies: refresh ? results : [...prevState.companies, ...results],
        loading: false,
        totalCompanies: total,
      }));
      setLoadingMore(false);
    } catch (err) {
      setState(prevState => ({
        ...prevState,
        error: true,
        loading: false,
        companies: [],
      }));
    }
  };

  useEffect(() => {
    page = 1;

    if (!loading) {
      setState(prevState => ({ ...prevState, loading: true }));
    }

    fetchCompanies(true);
  }, [query, category]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <UnexpectedError />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout
      menuType="light"
      metaData={{
        title: t('seo:pages.search.title', { value: query || category }),
        description: t('seo:pages.search.description'),
      }}
    >
      {(!loading && isEmpty(companies))
        ? <NotFound query={query} category={category} />
        : (
          <WrapperContent>
            <Wrapper>
              <Container>
                <Headline>
                  <Row>
                    <Col sm='6' className='banner-text'>
                      <span className="breadcrumbs">
                        <Translate>translation:search.breadcrumb</Translate> / {query || category}
                      </span>
                      <h1 className="companies_count">
                        <Translate options={{ count: totalCompanies }}>
                          translation:search.results.found.headline
                        </Translate>
                      </h1>
                    </Col>
                    <Col sm='6' className='search_input'>
                      <SearchInput placeholder={t('translation:search.placeholder')} />
                    </Col>
                  </Row>
                </Headline>
              </Container>
            </Wrapper>
            <ContentWrapper>
              <Container>
                <Row>
                  {map(companies, ({ id, name, logo1, couponsCount, dealsCount, slug }) => (
                    <Col key={id} sm='6' lg='4'>
                      <ResultCard
                        name={name}
                        logo={logo1}
                        deals={dealsCount}
                        coupons={couponsCount}
                        slug={slug}
                      />
                    </Col>
                  ))}
                </Row>
                {totalCompanies !== companies.length && (
                  <LoadMoreWrapper>
                    <Button
                      color='social'
                      onClick={() => {
                        if (loading || isLoadingMore || totalCompanies === companies.length) {
                          return;
                        }

                        setLoadingMore(true);
                        page++;
                        fetchCompanies();
                      }}
                    >
                      {isLoadingMore ? <Spinner color="light" /> : <Translate>translation:search.button.showMore</Translate>}
                    </Button>
                  </LoadMoreWrapper>
                )}
              </Container>
            </ContentWrapper>
          </WrapperContent>
      )}
      <HighlightsWrapper>
        <Container>
          <p className='heading-description'>
            <Translate>coupons:category.search</Translate>
          </p>
          <CategoryMenu color='darkBlue' size='larger' />
        </Container>
      </HighlightsWrapper>
      <CompanyWrapper>
        <Container>
          <p className='heading-description'>
            <Translate>coupons:category.search</Translate>
          </p>
          <PopularCompanies
            amount={24}
            backgroundColor={variables.color.white}
            includeHeading={false}
            heading={<Translate>translation:popular.offers</Translate>}
          />
        </Container>
      </CompanyWrapper>
    </Layout>
  );
};

export default Search;
