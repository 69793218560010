import React from 'react';
import { Translate } from 'Foundation/Translations';

export const categories = [
  {
    id: 1,
    name: 'Beauty',
    url: '/search?category=beauty',
    label: <Translate>translation:categories.beauty</Translate>,
    value: 'beauty',
  },
  {
    id: 2,
    name: 'Clothes',
    url: '/search?category=clothes',
    label: <Translate>translation:categories.clothes</Translate>,
    value: 'clothes',
  },
  {
    id: 3,
    name: 'Events',
    url: '/search?category=events',
    label: <Translate>translation:categories.events</Translate>,
    value: 'events',
  },
  {
    id: 4,
    name: 'Food',
    url: '/search?category=food',
    label: <Translate>translation:categories.food</Translate>,
    value: 'food',
  },
  {
    id: 5,
    name: 'Travel',
    url: '/search?category=travel',
    label: <Translate>translation:categories.travel</Translate>,
    value: 'travel',
  },
  {
    id: 6,
    name: 'Other',
    url: '/search?category=other',
    label: <Translate>translation:categories.other</Translate>,
    value: 'other',
  },
];