import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Brands from 'Assets/brands.svg';
import Codes from 'Assets/codes.svg';
import Offers from 'Assets/offers.svg';
import Users from 'Assets/users.svg';

const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 16px;

  h4 {
    padding-top: 15px;
  }
`

const Card = ({ type, amount, value }) => {
  const types = {
    brands: Brands,
    codes: Codes,
    offers: Offers,
    users: Users,
  };

  return (
    <Wrapper className='text-center'>
      <img src={types[type]} alt={type} />
      <h4>{amount}</h4>
      <h5>{value}</h5>
    </Wrapper>
  )
};

Card.propTypes = {
  type: string.isRequired,
  amount: string.isRequired,
  value: string.isRequired,
}

export default Card;
