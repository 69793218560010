import { node, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Footer from 'Components/Footer';
import Menu from 'Components/Menu';
import { variables } from 'Utils/Styles';

const getMenuBackground = ({ isSticky, menuType }) => {
  if (isSticky && menuType !== 'light') {
    return variables.color.darkBlue;
  }

  if (menuType === 'light') {
    return 'white';
  }

  return 'none';
}

const HeaderWrapper = styled.header`
  padding: ${props => (props.isSticky || props.menuType === 'light') ? '10px 0 10px 0' : '20px 0 20px 0'};
  position: ${props => props.menuType === 'light' ? 'fixed' : 'fixed'};
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-out;
  background-color: ${props => getMenuBackground(props)};
  z-index: 100;
  box-shadow: ${props => props.menuType === 'light' ? '0 2px 10px rgba(0, 0, 0, 0.10)' : 'none'};

  .navbar-brand {
    width: 165px;
  }

  .navbar {
    padding: 0;
  }
`;

const FooterWrapper = styled.div`
  background-color: ${variables.color.darkBlue};
  padding: 30px 0 30px 0;
`;

const Wrapper = styled.div`
  background-color: white;
`;

const MainLayout = ({ children, menuType, metaData }) => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      const scrollTop = event.target.scrollingElement.scrollTop;

      if (scrollTop > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  });

  return (
    <>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name='description' content={metaData.description} />
      </Helmet>
      <HeaderWrapper menuType={menuType} isSticky={isSticky}>
        <Menu menuType={menuType} isSticky={isSticky}/>
      </HeaderWrapper>
      <Wrapper>
        {children}
      </Wrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </>
  )
};

MainLayout.propTypes = {
  children: node.isRequired,
  menuType: string,
  metaData: shape({
    title: string.isRequired,
    description: string.isRequired,
  }).isRequired,
}

MainLayout.defaultProps = {
  menuType: 'primary',
}

export default MainLayout;
