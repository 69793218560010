import { noop } from 'lodash';
import { arrayOf, bool, func, shape, string, node } from 'prop-types';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Wrapper = styled.div`
  .reactSelect {
     max-width: 300px;
  }
`;

const Selector = ({ placeholder, options , onChange, isMulti, value, isDisabled, isSearchable }) => (
   <Wrapper>
      <Select
        className='reactSelect'
        name="filters"
        placeholder={placeholder}
        value={value}
        options={options}
        onChange={onChange}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
      />
   </Wrapper>
);

Selector.propTypes = {
  placeholder: node.isRequired,
  options: arrayOf(shape()).isRequired,
  onChange: func,
  value: string,
  isMulti: bool,
  isDisabled: bool,
  isSearchable: bool,
};

Selector.defaultProps = {
  onChange: noop,
  value: null,
  isMulti: false,
  isDisabled: false,
  isSearchable: false,
};

export default Selector;
