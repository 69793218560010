import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ArrowIcon from 'Assets/arrow.svg';
import BrowserExtensionIcon from 'Assets/browser-extension-icon.svg';
import MobileAppIcon from 'Assets/mobile-app.svg';
import WebAppIcon from 'Assets/web-app-icon.svg';
import Button from 'Components/Button';
import { Translate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  padding: 30px 0 30px 0;

  h2 {
    color: ${variables.color.darkBlue};
    font-weigth: ${variables.typography.baseHeadingFontWeight};
    padding: ${variables.typography.baseHeadingPadding};
  }

  p {
    color: ${variables.color.darkBlue};
    max-width: 400px;
  }

  .platform {
    padding: 10px;
  }

  .platform h4 {
    font-size: 22px;
  }
`

const PlatformIcon = styled.img`
  height: 25px;
`

const Arrow = styled.img`
  width: 20px;
  margin-left: 10px;
`

const Offer = () => (
  <Wrapper>
    <Container>
      <h2><Translate>translation:homepage.offer.heading</Translate></h2>
      <Row>
        <Col sm='6'>
          <p><Translate>translation:homepage.offer.firstDescription</Translate></p>
          <p><Translate>translation:homepage.offer.secondDescription</Translate></p>
          <Link to='#'>
            <Button color='link-pink'>
              <Translate>translation:homepage.offer.button.value</Translate>
              <Arrow src={ArrowIcon} />
            </Button>
          </Link>
        </Col>
        <Col sm='6'>
          <div className='platform'>
            <PlatformIcon src={WebAppIcon} />
            <h4><Translate>translation:homepage.offer.platform.web</Translate></h4>
          </div>
          <div className='platform'>
            <PlatformIcon src={MobileAppIcon} />
            <h4><Translate>translation:homepage.offer.platform.mobile</Translate></h4>
          </div>
          <div className='platform'>
            <PlatformIcon src={BrowserExtensionIcon} />
            <h4><Translate>translation:homepage.offer.platform.extension</Translate></h4>
          </div>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Offer;