import axios from 'axios';
import ClientOAuth2 from 'client-oauth2'
import { EventEmitter } from 'events'
import { get, isNil } from 'lodash'
import { apiUrl, clientId, clientSecret } from 'Utils/Constants';

export const LOCALSTORAGE_KEY = 'oauth2-tokens'

export const events = new EventEmitter()

const defaultTokens = {}

export const getTokens = () => {
  try {
    const result = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY))

    return {
      accessToken: get(result, 'accessToken'),
      refreshToken: get(result, 'refreshToken'),
    }
  } catch (err) {
    return defaultTokens
  }
}
export const setTokens = (input) => {
  const tokens = {
    accessToken: get(input, 'accessToken'),
    refreshToken: get(input, 'refreshToken'),
  }

  window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(tokens))
  events.emit(LOCALSTORAGE_KEY, tokens)
}

window.addEventListener('storage', ({ key, newValue }) => {
  if (key === LOCALSTORAGE_KEY) {
    setTokens(getTokens())
  }
})

const auth = new ClientOAuth2({
  clientId,
  clientSecret,
  accessTokenUri: `${apiUrl}/oauth/token`,
  authorizationUri: `${apiUrl}/oauth/token`,
  scopes: ['*'],
})

export const refresh = async () => {
  const { accessToken, refreshToken } = getTokens()
  if (!refreshToken) {
    return {}
  }

  console.log('refreshing token')

  const token = await auth.createToken(accessToken, refreshToken).refresh()

  setTokens({ accessToken: token.accessToken, refreshToken: token.refreshToken })
}

export const login = async (username, password) => {
  try {
    console.log('logging in')

    const token = await auth.owner.getToken(username, password)

    setTokens({ accessToken: token.accessToken, refreshToken: token.refreshToken })

    return true;
  } catch (err) {
    if (err.status !== 401) {
      throw err;
    }

    return false;
  }
}

export const socialLogin = async (provider, providerToken) => {
  try {
    const { accessToken, refreshToken } = getTokens()

    if (!isNil(accessToken) && !isNil(refreshToken)) {
      return false;
    }

    const response = await axios.post(
      `${apiUrl}/oauth/token`,
      {
        grant_type: 'social',
        client_id: clientId,
        client_secret: clientSecret,
        provider,
        access_token: providerToken,
      }
    );

    setTokens({ accessToken: response.data.access_token, refreshToken: response.data.refresh_token })
  } catch (err) {
    if (err.status !== 401) {
      throw err;
    }

    return false;
  }
};

export const logout = async () => {
  console.log('logging out')

  setTokens(defaultTokens)
}
