import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'coupons',
      'companies',
      'deals',
      'translation',
      'notifications',
      'seo',
    ],
    fallbackLng: 'en',
    debug: false,
    react: {
      useSuspense: false,
      wait: false,
    },
});

export default i18n;
