import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ContactIllustration from 'Assets/contactus.svg';
import Illustration from 'Assets/share-illustration.svg';
import UsersIcon from 'Assets/Icons/users.svg';
import BrandsIcon from 'Assets/Icons/brands.svg';
import SalesIcon from 'Assets/Icons/sales.svg';
import Button from 'Components/Button';
import PopularCompanies from 'Components/Popular/Company';
import { Translate } from 'Foundation/Translations';
import Layout from 'Templates/Layout/Main';
import { partnersEmail } from 'Utils/Constants';
import { variables } from 'Utils/Styles';

const HeadlineWrapper = styled.div`
  padding: 50px 0;
  margin-top: 60px;
  background-color: ${variables.color.lightGray};

  img {
    max-width: 100%;
  }

  .banner-text {
    h1 {
      font-weight: 600;
      color: ${variables.color.darkBlue};
      letter-spacing: ${variables.typography.baseLetterSpacing};
    }

    p {
      color: ${variables.color.lightBlue};
      font-weight: 500;
      font-size: ${variables.typography.baseFontFamily};
      letter-spacing: ${variables.typography.baseLetterSpacing};
    }

    .partner-description {
      width: 90%;
    }

    @media (max-width: 767px) {
      margin-bottom: ${variables.spacing.smaller};

      h1 {
        font-size: 28px;
      }

      .partner-description {
        width: 100%;
      }
    }
  }
`;

const StatisticsWrapper = styled.div`
  background-color: #fff;
  margin-top: ${variables.spacing.larger};
  padding-bottom: ${variables.spacing.larger};

  h1 {
    color: ${variables.color.darkblue};
    font-size: 28px;
    font-weight: 600;
    letter-spacing: ${variables.typography.baseLetterSpacing};
  }

  .items {
    padding: ${variables.spacing.normal} 0;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
        height: 50px;
        margin-bottom: ${variables.spacing.small};
      }

      h5 {
        letter-spacing: ${variables.typography.baseLetterSpacing};
        font-weight: 600;
        color: ${variables.color.darkBlue};
        font-size: 18px;
      }

      p {
        color: ${variables.color.lightBlue};
        letter-spacing: ${variables.typography.baseLetterSpacing};
      }
    }
  }
`;

const ContactWrapper = styled.div`
  background-color: ${variables.color.lightGray};
  padding-top: ${variables.spacing.larger};
  padding-bottom: ${variables.spacing.larger};

  img {
    max-width: 90%;
  }

  h1 {
    color: ${variables.color.darkblue};
    font-size: 28px;
    font-weight: 600;
    letter-spacing: ${variables.typography.baseLetterSpacing};
  }

  p {
    color: ${variables.color.lightBlue};
    font-weight: 500;
    font-size: ${variables.typography.baseFontFamily};
    letter-spacing: ${variables.typography.baseLetterSpacing};
  }
`;

const Partner = () => {
  const { t } = useTranslation();
  const openEmail = () => window.open(`mailto:${partnersEmail}?subject=${t('translation:partner.button.emailSubject')}`);

  return (
    <Layout
      menuType='light'
      metaData={{
        title: t('seo:pages.partner.title'),
        description: t('seo:pages.partner.description'),
      }}
    >
      <>
        <HeadlineWrapper>
          <Container>
            <Row>
              <Col md='6' className='align-self-center text-center'>
                <img src={Illustration} alt={t('translation:partner.illustration')} />
              </Col>
              <Col md='6' className='banner-text align-self-center'>
                <h1>
                  <Translate>translation:partner.headline</Translate>
                </h1>
                <div className='partner-description'>
                  <p>
                    <Translate>translation:partner.description</Translate>
                  </p>
                </div>
                <Button color='social' onClick={openEmail}>
                  <Translate>
                    translation:partner.button.contactUs
                  </Translate>
                </Button>
              </Col>
            </Row>
          </Container>
        </HeadlineWrapper>
        <StatisticsWrapper>
          <Container>
            <h1>
              <Translate>translation:partner.statistics.headline</Translate>
            </h1>
            <div className='items'>
              <Row>
                <Col sm='4' className='text-center'>
                  <div className='item'>
                    <img src={UsersIcon} alt={t('translation:partner.statistics.items.users.illustration')} />
                    <h5>
                      <Translate>translation:partner.statistics.items.users.headline</Translate>
                    </h5>
                    <p>
                      <Translate>translation:partner.statistics.items.users.description</Translate>
                    </p>
                  </div>
                </Col>
                <Col sm='4' className='text-center'>
                  <div className='item'>
                    <img src={BrandsIcon} alt={t('translation:partner.statistics.items.brands.illustration')} />
                    <h5>
                      <Translate>translation:partner.statistics.items.brands.headline</Translate>
                    </h5>
                    <p>
                      <Translate>translation:partner.statistics.items.brands.description</Translate>
                    </p>
                  </div>
                </Col>
                <Col sm='4' className='text-center'>
                  <div className='item'>
                    <img src={SalesIcon} alt={t('translation:partner.statistics.items.sales.illustration')} />
                    <h5>
                      <Translate>translation:partner.statistics.items.sales.headline</Translate>
                    </h5>
                    <p>
                      <Translate>translation:partner.statistics.items.sales.description</Translate>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </StatisticsWrapper>
        <ContactWrapper>
          <Container>
            <Row>
              <Col md='6' className='align-self-center'>
                <h1>
                  <Translate>translation:partner.contact.headline</Translate>
                </h1>
                <div className='partner-description'>
                  <p>
                    <Translate>translation:partner.contact.description</Translate>
                  </p>
                </div>
                <Button color='social' onClick={openEmail}>
                  <Translate>
                    translation:partner.button.contactUs
                  </Translate>
                </Button>
              </Col>
              <Col md='6' className='align-self-center'>
                <img src={ContactIllustration} alt={t('translation:partner.contact.illustration')} />
              </Col>
            </Row>
          </Container>
        </ContactWrapper>
        <PopularCompanies amount={12} includeHeading={false} />
      </>
    </Layout>
  );
};

export default Partner;