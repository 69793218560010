import axios, { CancelToken } from 'axios'
import { isEmpty } from 'lodash'
import { getTokens, refresh, setTokens } from 'Foundation/Authentication/OAuth2'
import { apiUrl, clientUrl } from 'Utils/Constants';

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'json',
})

instance.interceptors.request.use(
  async config => {
    // Axios bug: this ensures content-type is always set
    if (!config.data) {
      config.data = {}
    }

    let tokens = getTokens()

    if (isEmpty(tokens.accessToken)) {
      try {
        await refresh()

        // Cancel the me call, after refreshing tokens
        if (config.url === '/me') {
          config.cancelToken = new CancelToken((cancel) => cancel('Cancel repeated request'))
        }
      } catch (errInner) {
        config.cancelToken = new CancelToken((cancel) => cancel('failed to refresh tokens'))
        setTokens({})
      }

      tokens = getTokens()

      if (isEmpty(tokens.accessToken)) {
        return config
      }
    }

    config.headers['authorization'] = `Bearer ${tokens.accessToken}`

    return config
  },
  err => Promise.reject(err),
)

instance.interceptors.response.use(
  response => response,
  async (err) => {
    const orgRequest = err.config

    // Only attempt to retry 401's that hasn't been retried before
    if (err.response.status === 401 && !orgRequest._retry) {
      orgRequest._retry = true

      let tokens = getTokens()

      if (isEmpty(tokens.refreshToken)) {
        return Promise.reject(err)
      }

      try {
        await refresh()

        // Cancel the me call, after refreshing tokens
        if (orgRequest.url === '/me') {
          orgRequest.cancelToken = new CancelToken((cancel) => cancel('Cancel repeated request'))
        }
      } catch (errInner) {
        setTokens({})
        return Promise.reject(err)
      }

      // Retry the request, with new tokens
      return instance(orgRequest)
    }

    if (err.response.status === 403) {
      window.location.href = `${clientUrl}/dashboard`;

      return Promise.reject(err);
    }

    return Promise.reject(err)
  },
)

export default instance
