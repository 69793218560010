import { Formik } from 'formik';
import { isNil } from 'lodash';
import { shape } from 'prop-types';
import React from 'react';
import { Form } from 'reactstrap';
import {
  Link,
  withRouter,
} from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components';
import DarkLogo from 'Assets/logo-dark.svg';
import Button from 'Components/Button';
import Input from 'Components/Input';
import Axios from 'Foundation/Axios';
import { Translate } from 'Foundation/Translations';
import { isEmail, isMinimumLength, isMatching } from 'Foundation/Validation';

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  color: #fff;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: perspective(1px) translateY(-50%) translateX(-50%);
  -ms-transform: perspective(1px) translateY(-50%) translateX(-50%);
  transform: perspective(1px) translateY(-50%) translateX(-50%);
  left: 50%;
  text-align: center;

  .content {
    margin-top: 30px;
    text-align: initial;
  }
`;

const PasswordReset = ({ history, location }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const token = (new URLSearchParams(location.search)).get('token');

  const renderEmailForm = () => (
    <Formik
      initialValues={{ email: null }}
      validate={({ email }) => {
        const errors = {}

        if (isEmail(email)) {
          errors.email = t('translation:forms.validation.email');
        }

        return errors;
      }}
      onSubmit={async ({ email }, { setSubmitting }) => {
        try {
          setSubmitting(true);

          await Axios.post(
            '/auth/reset-password',
            { email }
          );

          addToast(
            <Translate>notifications:user.password.request</Translate>,
            { appearance: 'success' },
          );

          history.push('/login');
        } catch (err) {
          setSubmitting(false);

          addToast(
            <Translate>notifications:general.error</Translate>,
            { appearance: 'error' },
          );
        }
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values: {
          email,
        }
      }) => (
          <Form onSubmit={handleSubmit}>
            <div className='content'>
              <Input
                type='email'
                name='email'
                label={<Translate>translation:forms.email</Translate>}
                onChange={handleChange}
                errorMessage={errors.email}
                value={email || ''}
                required
              />
            </div>
            <Button
              type='submit'
              color='primary'
              style={{
                width: '150px',
                letterSpacing: '2px',
                borderRadius: '16px',
                padding: '5px',
                fontSize: '14px',
              }}
              disabled={isSubmitting}
            >
              <Translate>translation:forms.button.submit</Translate>
            </Button>
          </Form>
        )}
    </Formik>
  );

  const renderPasswordReset = () => (
    <Formik
      initialValues={{
        password: null,
        passwordRepeat: null,
      }}
      validate={({ password, passwordRepeat }) => {
        const errors = {}

        if (isMinimumLength(password, 8)) {
          errors.password = t('translation:forms.validation.password');
        }

        if (isMatching(password, passwordRepeat)) {
          errors.passwordRepeat = t('translation:forms.validation.passwordMatch');
        }

        return errors;
      }}
      onSubmit={async ({ password }, { setSubmitting }) => {
        try {
          setSubmitting(true);

          await Axios.post(
            '/auth/reset-password/reset',
            { password, token }
          );

          addToast(
            <Translate>notifications:user.password.updated</Translate>,
            { appearance: 'success' },
          );

          history.push('/login');
        } catch (err) {
          setSubmitting(false);

          addToast(
            <Translate>notifications:general.error</Translate>,
            { appearance: 'error' },
          );
        }
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values: {
          password,
          passwordRepeat,
        }
      }) => (
          <Form onSubmit={handleSubmit}>
            <div className='content'>
              <Input
                type='password'
                name='password'
                label={<Translate>translation:forms.password</Translate>}
                onChange={handleChange}
                errorMessage={errors.password}
                value={password || ''}
                required
              />
              <Input
                type='password'
                name='passwordRepeat'
                label={<Translate>translation:forms.passwordRepeat</Translate>}
                onChange={handleChange}
                errorMessage={errors.passwordRepeat}
                value={passwordRepeat || ''}
                required
              />
            </div>
            <Button
              type='submit'
              color='primary'
              style={{
                width: '150px',
                letterSpacing: '2px',
                borderRadius: '16px',
                padding: '5px',
                fontSize: '14px',
              }}
              disabled={isSubmitting}
            >
              <Translate>translation:forms.button.submit</Translate>
            </Button>
          </Form>
        )}
    </Formik>
  );

  return (
    <Wrapper>
      <Link to='/'>
        <img src={DarkLogo} alt='Discode' />
      </Link>
      {isNil(token) ? renderEmailForm() : renderPasswordReset() }
    </Wrapper>
  )
};

PasswordReset.propTypes = {
  history: shape().isRequired,
  location: shape().isRequired,
}

export default withRouter(PasswordReset);