import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import CategoryMenu from 'Components/Category/Menu';
import About from 'Components/HomePage/About';
import Headline from 'Components/HomePage/Headline';
import Save from 'Components/HomePage/Save';
import Share from 'Components/HomePage/Share';
import Offer from 'Components/HomePage/Offer';
import PopularCodes from 'Components/Popular/Codes';
import PopularCompanies from 'Components/Popular/Company';
import { Translate } from 'Foundation/Translations';
import Layout from 'Templates/Layout/Main';
import { variables } from 'Utils/Styles';

const HighlightsWrapper = styled.div`
  padding: 30px 0 30px 0;

  h2 {
    color: ${variables.color.primary};
  }

  .heading-description {
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${variables.typography.smallFontSize};
  }
`;

const Highlights = () => (
  <HighlightsWrapper>
    <Container>
      <h2><Translate>coupons:coupon.use</Translate></h2>
      <p className='heading-description'>
        <Translate>coupons:category.search</Translate>
      </p>
      <CategoryMenu color='darkBlue' size='larger' />
      <p className='heading-description'>
        <Translate>coupons:coupon.popularToday</Translate>
      </p>
      <PopularCodes color='darkBlue' />
    </Container>
  </HighlightsWrapper>
)

export default () => {
  const { t } = useTranslation();

  return (
    <Layout
      metaData={{
        title: t('seo:pages.home.title'),
        description: t('seo:pages.home.description'),
      }}
    >
      <Headline />
      <Highlights />
      <About />
      <Save />
      <Share />
      <Offer />
      <PopularCompanies />
    </Layout>
  );
};
