import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import MobileApp from 'Assets/app-mockup.svg';
// import AppStore from 'Assets/app-store.svg';
// import GooglePlay from 'Assets/google-play.svg';
import TopSectionImg from 'Assets/top-section.png';
import SearchInput from 'Components/Input/Search';
import { HtmlTranslate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const TopSection = styled.div`
  background-image: url(${TopSectionImg});
  background-size: cover;
  height: 600px;
  width: 100%;
  padding-top: 150px;
  margin-bottom: 70px;

  @media (max-width: 767px) {
    height: auto;
    padding-top: 64px;
    margin-bottom: 0;
    background-image: none;
    background-color: ${variables.color.darkBlue};
  }

  .banner {
    height: 630px;
  }

  h1 {
    color: ${variables.color.white};
    font-weight: 600;
    font-size: 56px;
    margin-bottom: ${variables.spacing.larger};

    @media (max-width: 767px) {
      font-size: 40px;
    }
  }

  h1 span {
    color: ${variables.color.primary};
    display: block;
  }

  .banner-text {
    padding-top: 50px;

    @media (max-width: 767px) {
      padding-bottom: 50px;
    }
  }
`;

// const Store = styled.img`
//   margin-right: ${variables.spacing.normal};
// `;

const Headline = () => {
  const { t } = useTranslation();

  return (
    <TopSection>
      <Container>
        <Row>
          <Col sm='6' className='banner-text'>
            <h1><HtmlTranslate>translation:homepage.headline</HtmlTranslate></h1>
            <SearchInput placeholder={t('translation:forms.placeholders.shop')} />
            {/* <div>
              <Store src={AppStore} />
              <Store src={GooglePlay} />
            </div> */}
          </Col>
          <Col sm='6' className='d-none d-sm-block text-right'>
            {/* <img src={MobileApp} alt='Mobile app example' className='banner'/> */}
          </Col>
        </Row>
      </Container>
    </TopSection>
  );
};

export default Headline;
