import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ShareIlustration from 'Assets/share-illustration.svg';
import Button from 'Components/Button';
import { Translate } from 'Foundation/Translations';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  background-color: ${variables.color.lightGray};
  padding: 30px 0 30px 0;

  h2 {
    color: ${variables.color.darkGray};
    font-weigth: ${variables.typography.baseHeadingFontWeight};
    padding: ${variables.typography.baseHeadingPadding};
  }

  p {
    color: ${variables.color.darkGray};
    letter-spacing: ${variables.typography.baseLetterSpacing};
  }

  img {
    width: 300px;
  }
`

const Column = styled(Col)`
  padding: 10px 30px;
`

const Share = () => (
  <Wrapper>
    <Container>
      <Row className='flex-column-reverse flex-md-row'>
        <Column sm='6' className='align-self-center'>
          <div>
            <h2><Translate>translation:homepage.save.heading</Translate></h2>
            <p><Translate>translation:homepage.save.description</Translate></p>
            <Link to='#'>
              <Button color='primary' style={{ borderRadius: '20px' }}>
                <Translate>translation:homepage.save.button.value</Translate>
              </Button>
            </Link>
          </div>
        </Column>
        <Column sm='6' className='align-self-center text-center'>
          <img src={ShareIlustration} alt='' />
        </Column>
      </Row>
    </Container>
  </Wrapper>
);

export default Share;