import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Illustration from 'Assets/contactus_illustration.svg';
import Button from 'Components/Button';
import { Translate } from 'Foundation/Translations';
import Template from 'Templates/Layout/Main';
import { contactEmail } from 'Utils/Constants';
import { variables } from 'Utils/Styles';

const Wrapper = styled.div`
  padding: 150px 0 150px;
  margin-top: 60px;
  background-color: ${variables.color.lightGray};

  img {
    max-width: 100%;
  }

  .banner-text {
    h1 {
      font-weight: 600;
      color: ${variables.color.darkBlue};
    }

    p {
      color: ${variables.color.lightBlue};
      font-weight: 500;
      font-size: ${variables.typography.baseFontFamily};
      letter-spacing: ${variables.typography.baseLetterSpacing};
    }

    .privacy-description {
      width: 90%;
    }

    @media (max-width: 767px) {
      margin-bottom: ${variables.spacing.smaller};

      h1 {
        font-size: 28px;
      }

      .privacy-description {
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 50px 0 50px 0;
  }
`;

export default () => {
  const { t } = useTranslation();
  const openEmail = () => window.open(`mailto:${contactEmail}?subject=${t('translation:contact.emailSubject')}`);

  return (
    <Template
      menuType='light'
      metaData={{
        title: t('seo:pages.contact.title'),
        description: t('seo:pages.contact.description'),
      }}
    >
      <Wrapper>
        <Container>
          <Row>
            <Col md='6' className='banner-text align-self-center'>
              <h1>
                <Translate>translation:contact.headline</Translate>
              </h1>
              <div className='privacy-description'>
                <p>
                  <Translate>translation:contact.description</Translate>
                </p>
              </div>
              <Button color='social' className='uppercase' onClick={openEmail}>
                <Translate>
                  translation:contact.button
                </Translate>
              </Button>
            </Col>
            <Col md='6' className='align-self-center text-center'>
              <img src={Illustration} alt={t('translation:contact.headline')} />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Template>
  );
};
