import { map } from 'lodash';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FaqImg from 'Assets/faq.svg';
import Plus from 'Assets/Icons/plus_simple.svg';
import Minus from 'Assets/Icons/minus_simple.svg';
import Button from 'Components/Button';
import { Translate } from 'Foundation/Translations';
import Layout from 'Templates/Layout/Main';
import { contactEmail } from 'Utils/Constants';
import { variables } from 'Utils/Styles';

const HeadlineWrapper = styled.div`
  padding: 50px 0;
  margin-top: 60px;
  background-color: ${variables.color.lightGray};

  img {
    max-width: 60%;
  }

  .banner-text {
    h1 {
      font-weight: 600;
      color: ${variables.color.darkBlue};
    }

    p {
      color: ${variables.color.lightBlue};
      font-weight: 500;
      font-size: ${variables.typography.baseFontFamily};
      letter-spacing: ${variables.typography.baseLetterSpacing};
    }

    .faq-description {
      width: 90%;
    }

    @media (max-width: 767px) {
      margin-bottom: ${variables.spacing.smaller};

      h1 {
        font-size: 28px;
      }

      .faq-description {
        width: 100%;
      }
    }
  }

  @media (max-width: 992px) {
    img {
      max-width: 100%;
    }
  }
`;

const ContentWrapper = styled.div`
  background-color: ${variables.color.lightGray};
  padding-top: ${variables.spacing.larger};
  padding-bottom: ${variables.spacing.larger};

  h1 {
    color: ${variables.color.primary};
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .questions {
    padding: 10px 50px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }
`;

const ContactWrapper = styled.div`
  margin-top: ${variables.spacing.larger};
  padding-bottom: ${variables.spacing.larger};

  h1 {
    color: ${variables.color.primary};
    font-size: 28px;
    font-weight: 600;
    letter-spacing: ${variables.typography.baseLetterSpacing};
  }

  p {
    color: ${variables.color.lightBlue};
    font-weight: 500;
    font-size: 18px;
    letter-spacing: ${variables.typography.baseLetterSpacing};
  }
`;

const QuestionWrapper = styled.div`
  padding: ${variables.spacing.normal};
  border-bottom: 1px solid ${variables.color.mediumGray};

  &:last-child {
    border-bottom: none;
  }

  .headline {
    display: flex;
    flex-direction: row;
    text-align: center;

    img {
      padding-right: ${variables.spacing.normal};
    }

    h1 {
      color: ${variables.color.darkBlue};
      cursor: pointer;
      font-weight: 600;
      font-size: 24px;
      letter-spacing: ${variables.typography.baseLetterSpacing};
      vertical-align: middle;
      margin: 0;
    }
  }

  .content {
    margin-top: ${variables.spacing.normal};
    margin-left: 48px;
    padding-left: ${variables.spacing.normal};
    border-left: 4px solid ${variables.color.lightPink};
    color: ${variables.color.lightBlue};
    font-weight: 500;
    font-size: 18px;
    letter-spacing: ${variables.typography.baseLetterSpacing};

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
`;

const Question = ({ id }) => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);

  return (
    <QuestionWrapper onClick={() => setActive(!isActive)}>
      <div className='headline'>
        <img src={isActive ? Minus : Plus} alt={t('translation:faq.iconDescription')} />
        <h1>
          <Translate>{`translation:faq.items.${id}.headline`}</Translate>
        </h1>
      </div>
      {isActive && (
        <div className='content'>
          <Translate>{`translation:faq.items.${id}.description`}</Translate>
        </div>
      )}
    </QuestionWrapper>
  );
};

Question.defaultProps = {
  id: string.isRequired,
};

export default () => {
  const { t } = useTranslation();

  const items = [
    'how',
    'use',
    'share',
    'personal',
    'multiple',
    'account',
  ];

  return (
    <Layout
      menuType='light'
      metaData={{
        title: t('seo:pages.faq.title'),
        description: t('seo:pages.faq.description'),
      }}
    >
      <>
        <HeadlineWrapper>
          <Container>
            <Row>
              <Col lg='4' md='6' className='banner-text align-self-center'>
                <h1>
                  <Translate>translation:faq.headline</Translate>
                </h1>
                <div className='faq-description'>
                  <p>
                    <Translate>translation:faq.description</Translate>
                  </p>
                </div>
              </Col>
              <Col lg='8' md='6' className='align-self-center text-center'>
                <img src={FaqImg} alt={t('translation:faq.illustration')} />
              </Col>
            </Row>
          </Container>
        </HeadlineWrapper>
        <ContentWrapper>
          <Container>
            <h1>
              <Translate>translation:faq.questionsHeadline</Translate>
            </h1>
            <div className='questions'>
              {map(items, item => <Question key={item} id={item} />)}
            </div>
          </Container>
        </ContentWrapper>
        <ContactWrapper>
          <Container>
            <h1>
              <Translate>translation:faq.contact.headline</Translate>
            </h1>
            <Row>
              <Col md='6'>
                <p>
                  <Translate>translation:faq.contact.description</Translate>
                </p>
              </Col>
              <Col md='6' className='align-self-center'>
                <div className='float-md-right' onClick={() => window.open(`mailto:${contactEmail}`)}>
                  <Button color='social uppercase'>
                    <Translate>
                      translation:faq.contact.button.contactUs
                    </Translate>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </ContactWrapper>
      </>
    </Layout>
  );
};
